<template>

  <div>
    <hr class="border-sw-gray-light">
  </div>

</template>

<script>

export default {
  name: 'Div',
}
</script>
