<template>

  <div :class="[page?'page_'+page.id:'']">

    <div v-for="(content,index) in contents" :key="content.id">
      <section :id="'c' + content.id" class="mx-auto" :class="[content.appearance.frameClass == 'default' ? 'max-w-7xl px-4 sm:px-6 lg:px-16' : '']">

        <Space :space="content.appearance.spaceBefore" v-if="!(index==0 && content.type == 'menu_pages')"/>


        <div :class="[content.appearance.frameClass == 'default' ? 'rounded-lg' : '', content.appearance.backgroundColor != '' ? 'bg-sw-' + content.appearance.backgroundColor : '', content.appearance.backgroundPadding == '1' ? ' p-4 sm:p-8 lg:p-10' : '', content.appearance.textColor == 'white' ? ' text-' + content.appearance.textColor : '']">

          <div :class="[content.appearance.frameClass == 'none-2' ? 'mx-auto max-w-7xl px-4 sm:px-6 lg:px-16' :'']">

            <component v-if="content.type != 'spotheadless_integrations' && content.type != 'shortcut'" :is="content.type" :content="content.content" :nestedContent="content.children" :appearance="content.appearance" :key="content.id" :page="page"/>
            <div v-else-if="content.type == 'shortcut'">
              <div v-for="shortcut in content.content['shortcut']" :key="shortcut.id" :class="[shortcut.appearance.frameClass == 'default' ? 'rounded-lg' : '', shortcut.appearance.backgroundColor != '' ? 'bg-sw-' + shortcut.appearance.backgroundColor : '', shortcut.appearance.backgroundPadding == '1' ? ' p-4 sm:p-8 lg:p-10' : '', shortcut.appearance.textColor == 'white' ? ' text-' + shortcut.appearance.textColor : '']">
                <component :is="shortcut.type" :content="shortcut.content" :appearance="shortcut.appearance" :page="page"/>
              </div>
            </div>

            <div class="space-y-4" v-else-if="content.type == 'spotheadless_integrations'">
              <div :class="[content.content.header != '' || content.content.subheader != '' || content.content.bodytext != '' ? 'mb-4 md:mb-8' : '']">
                <Headers :content="content.content"/>
                <Bodytext :content="content.content"/>
              </div>
              <component v-if="content.type == 'spotheadless_integrations'" :is="content.content.data.name" :data="content.content.data" :key="content.id" :appearance="content.appearance" :page="page" :languages="languages" :season="season" />
              <Link class="w-full" linkClass="w-full" :style="0" :text="content.content.headerLink.title" :link="content.content.headerLink" v-if="content.content.headerLink && content.content.headerLink.title"/>
            </div>

          </div>

        </div>

        <Space :space="content.appearance.spaceAfter"/>

      </section>
    </div>
  </div>

</template>

<script>
import Html from "@/components/contents/Html";
import Image from "@/components/contents/Image";
import Table from "@/components/contents/Table";
import Text from "@/components/contents/Text";
import Textpic from "@/components/contents/Textpic";
import Spotheadless_textpicslider from "@/components/contents/Textpicslider";
import Calltoaction from "@/components/contents/Calltoaction";
import Spotheadless_teaser from "@/components/contents/Teaser";
import Menu_subpages from "@/components/contents/MenuSubpages";
import Space from "@/components/contents/Space";
import Div from "@/components/contents/Div";
import Headers from "@/components/contents/partials/Headers";
import Bodytext from "@/components/contents/partials/Bodytext";
import Link from "@/components/contents/partials/Link";
import Video from "@/components/contents/Video"

import Weather from "@/components/integrations/Weather";
import Infosnow from "@/components/integrations/Infosnow";
import Webcams from "@/components/integrations/Webcams";
import Restaurants from "@/components/integrations/Restaurants";
import Avalanche from "@/components/integrations/Avalanche";
import Events from "@/components/integrations/Events";


export default {
  name: 'Contents',
  props: {
    languages: Object,
    page: Object,
    contents: Object,
    season: String
  },
  components: {
    Html,
    Image,
    Table,
    Text,
    Textpic,
    Spotheadless_textpicslider,
    Calltoaction,
    Spotheadless_teaser,
    Menu_subpages,
    Space,
    Div,
    Video,

    Headers,
    Bodytext,
    Link,

    Weather,
    Infosnow,
    Webcams,
    Restaurants,
    Avalanche,
    Events,
  },
}

</script>

<style>
.hide-scroll-bar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}
</style>
