<template>
  <div>

    <div v-if="data.layout == 'livestatus'">
      <Livestatus :data="data" :season="season" />
    </div>

    <div v-else-if="data.layout == 'timeschedule'">
      <Timeschedule :data="data.jsonTimeschedule"/>
    </div>

    <div v-else>

      <div v-if="resort">
        <tabs>
          <tab :title="$t('labels.' + tab)" :icon="tab" v-for="(tab, index) in data.json.tabs" :key="index">
            <component :is="tab" :data="resort" />
          </tab>
        </tabs>
      </div>

      <div v-else>
        <tabs>
          <tab :title="$t('labels.' + tab)" :icon="tab" v-for="(tab, index) in data.json.tabs" :key="index">

            <ul class="accordion">
              <li v-for="(resort, index) in resorts" :key="index" :class="[isOpen.includes(resort) ? 'is-active' : '']">
                <a aria-expanded="false" @click="toggleAccordion(resort)" :class="[isOpen.includes(resort) ? 'open' : '']" >
                  <InfosnowItem
                      v-if="tab == 'lifts' && resort.facilities.numberOfActiveFacilities > 0 && resort.facilities.isActive == true"
                      :resort="resort"
                      :selected="selected"
                      :status="resort.facilities.numberOfOpenFacilities  > 0"
                      :name="resort.info.resortName"
                      :icon="'icon--' + tab"
                      :info="resort.facilities.numberOfOpenFacilities + ' / ' + resort.facilities.numberOfActiveFacilities "
                      :link="resort.info.facilites.intermaps.de"
                  />
                  <InfosnowItem
                      v-if="tab == 'slopes' && resort.slopes.isActive == true "
                      :status="resort.slopes.numberOfOpenSlopes > 0"
                      :name="resort.info.resortName"
                      :icon="'icon--' + tab"
                      :info="resort.slopes.numberOfOpenSlopes + ' / ' + resort.slopes.numberOfSlopes"
                      :link="resort.info.slopes.intermaps.de"
                  />
                  <InfosnowItem
                      v-if="tab == 'snowpark' && resort.parks.isActive == true "
                      :status="resort.parks.numberOfOpenParks > 0"
                      :name="resort.info.resortName"
                      :icon="'icon--' + tab"
                      :info="resort.parks.numberOfOpenParks != undefined || resort.parks.numberOfParks != undefined ? resort.parks.numberOfOpenParks + ' / ' + resort.parks.numberOfParks : null"
                      :link="resort.info.other.intermaps.parks.de"
                  />
                  <InfosnowItem
                      v-if="tab == 'trails' && resort.trails.isActive == true "
                      :status="resort.trails.numberOfOpenTrails > 0"
                      :name="resort.info.resortName"
                      :icon="'icon--' + tab"
                      :info="resort.trails.numberOfOpenTrails != undefined || resort.trails.numberOfTrails != undefined ? resort.trails.numberOfOpenTrails + ' / ' + resort.trails.numberOfTrails : null"
                      :link="null"
                  />
                  <InfosnowItem
                      v-if="tab == 'summer' && resort.diverses.isActive == true"
                      :resort="resort"
                      :status="resort.diverses.numberOfOpenDiverses > 0"
                      :name="resort.info.resortName"
                      :icon="'icon--' + tab"
                      :info="resort.diverses.numberOfOpenDiverses + ' / ' + resort.diverses.numberOfDiverses "
                      :link="null"
                  />
                </a>

                <div v-show="isOpen.includes(resort)"  class="accordion-content" data-tab-content="" role="region" aria-labelledby="accordion-facilites-38-label" aria-hidden="true" id="brslil-accordion">
                  <component :is="tab" :data="resort" />
                </div>

              </li>
            </ul>
          </tab>
        </tabs>
      </div>
    </div>
  </div>
</template>

<script>

import axios from "axios";
import Tabs from "@/components/Tabs";
import Tab from "@/components/Tab";
import InfosnowItem from "@/components/integrations/partials/Infosnow/InfosnowItem";
import Lifts from "@/components/integrations/partials/Infosnow/Tabs/Lifts.vue";
import Slopes from "@/components/integrations/partials/Infosnow/Tabs/Slopes.vue";
import Snowpark from "@/components/integrations/partials/Infosnow/Tabs/Snowpark.vue";
import Summer from "@/components/integrations/partials/Infosnow/Tabs/Summer.vue";
import Trails from "@/components/integrations/partials/Infosnow/Tabs/Trails.vue";
import Livestatus from "@/components/integrations/partials/Infosnow/Livestatus.vue";
import Timeschedule from "@/components/integrations/partials/Infosnow/Timeschedule.vue";

export default {
  name: 'Infosnow',
  props: {
    data: Object,
    season: String
  },
  components: {
    InfosnowItem,
    Tabs,
    Tab,
    Lifts,
    Slopes,
    Snowpark,
    Summer,
    Trails,
    Livestatus,
    Timeschedule,
  },
  data() {
    return {
      tabs: [],
      isOpen: [],
      resort: null,
      resorts: null,
      //season: 'wi'
    }
  },
  methods: {
    toggleAccordion(index) {
      if (this.isOpen.includes(index)) {
        this.isOpen = this.isOpen.filter(i => i !== index);
        return;
      }
      this.isOpen.push(index);
    }
  },
  mounted() {
    axios.get(this.data.json.pathJson).then(response => {
      if(this.data.json.stations.length === 1) {
        this.resort = response.data['resorts'][this.data.json.stations];
      } else {
        this.resorts = response.data['resorts'];
      }
      //if (this.data.season===2)  this.season = 'su';
    })
  },
}
</script>
