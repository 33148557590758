<template>

      <div  v-if="event" class="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-16 overflow-hidden">
        <div v-if="urlback">
          <a v-bind:href="urlback" class="block p-3 pl-0 mt-3" >
            <svg class="h-4 w-4 -mt-1 inline-block"><use xlink:href="#icon--menu-arrow"></use></svg> {{ $t('labels.back') }}
          </a>
        </div>
        <swiper
            v-if="event.images"
            :slides-per-view="1"
            :space-between="0"
            :pagination="{ clickable: true }"
            :breakpoints="breakpoints"
        >
          <swiper-slide v-for="(image, index) in event.images" :key="index">
            <img class="w-full min-h-56 h-56 md:h-96 object-cover mb-8 lg:mb-12" :src="'https://res.cloudinary.com/dgr25kh64/image/fetch/c_fill,f_auto,h_400,q_80,w_1200/' + image.detail_picture_url" alt="">
          </swiper-slide>
        </swiper>

        <div>
          <h1 class="font-headline mb-4 md:mb-8">{{ event.name }}</h1>
          <div class="mb-4 md:mb-8" v-if="event.start">
            <h2>
              <span v-if="event.start">{{ event.start }}</span>
              <span v-if="event.start != event.end"> - {{ event.end }}</span>
              <span v-if="event.startTime"> | {{ event.startTime }}</span><span v-if="event.endTime"> - {{ event.endTime }}</span>
            </h2>
          </div>
          <p v-if="event.text" v-html="event.text"></p>

          <div v-if="event.links" class="mt-4 md:mt-8" >
            <p  v-for="(link, index) in event.links" :key="index">
              <a :href="link.url" :target="link.target">{{ link.name }}</a><br/>
            </p>
          </div>
        </div>

        <div class="mt-10 py-6 border-t border-b border-d-blue md:flex md:justify-between space-y-6 md:space-y-0 md:space-x-8">
          <div class="sm:flex space-y-6 sm:space-y-0 sm:space-x-8">
            <div class="w-full">
              <p>
                <b v-if="event.company">{{ event.company }}</b><br>
                <span v-if="event.street">{{ event.street }} {{ event.houseNumber }}</span><br>
                <span v-if="event.zipCode">{{ event.zipCode }}</span> - <span v-if="event.city">{{ event.city }}</span><br>
              </p>
              <p>
                <a :href="'tel:' + event.phone.replace(/\s/g, '').toLowerCase()" v-if="event.phone">{{ event.phone }}</a><br>
                <a :href="'mailto:' + event.email.replace(/\s/g, '').toLowerCase()" v-if="event.email">{{ event.email }}</a><br>
                <a :href="event.website" v-if="event.website">{{ event.website }}</a>
              </p>
            </div>
          </div>
        </div>

      </div>

</template>

<script>

import axios from "axios";
import Mixins from "@/Mixins";
import {Swiper, SwiperSlide} from 'swiper/vue';
import SwiperCore, {
  Pagination
} from 'swiper';
SwiperCore.use([Pagination]);

export default {
  name: 'EventDetail',
  mixins: [Mixins],
  props: {
    slug: String,
    id: String,
    urlback : String
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      event: {},
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    let path = window.location.pathname;
    let urlApi = 'https://api.corvatsch-diavolezza.ch';
    let that = this;
    path = path.replace('/'+that.slug, '');
    axios.get(urlApi+path).then(response => {
      response.data['content']['colPos0'].forEach((content) => {
        if (content.type==='spotheadless_integrations' && content['content']['data']['name']==='events'){
          that.event = content['content']['data']['json'].filter(function (event) {
            let slugPage = '/'+that.slug;
            if (event.slug === slugPage) {
              return true;
            }
          })[0];
        }
      })
    })
  }
}

</script>
