<template>

  <div v-if="data.trails.list">

    <div class="toggle-bg-color" v-for="(trail, index) in data.trails.list" :key="index">

      <div class="accordion-content px-4 py-4 md:py-8" >

        <div class="grid grid-cols-12 gap-1 md:gap-4">
          <div class="col-auto md:col-span-1 flex items-center justify-center">
            <div :class="trail.StatusID == 8 ? 'text-sw-green' : 'text-sw-red'">
              <svg class="w-6 h-6 md:mx-auto"><use :xlink:href="'#' + $t('labels.type-icon-' + trail.TypID)"></use></svg>
            </div>
          </div>
          <div class="col-span-6 md:col-span-3">
            <p class="mb-0 pl-2 md:pl-0" v-if="trail.Name">
              {{trail.Name}}<br>
              <span class="text-sm text-gray-400">{{ $t('labels.trail-type-' + trail.TypID) }}</span>
            </p>
          </div>
          <div class="col-span-4 md:col-span-2 font-black" :class="trail.StatusID == 8 ? 'text-sw-green' : 'text-sw-red'">
            <p class="text-right md:text-left todo-color">{{ $t('labels.trail-status-' + trail.StatusID) }}</p>
          </div>

          <div class="col-span-12 md:col-span-6 lg:col-span-3 xxl:col-span-2" v-if="trail.LaengeDynamisch">
            <br class="md:hidden">
            <div class="flex">
              <div class="icon-wrap__text">
                <span class="font-bold">{{ $t('labels.length') }}:</span>
                {{trail.LaengeDynamisch}} m
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>

</template>

<script>
import Mixins from "@/Mixins";

export default {
  name: 'Trails',
  mixins: [Mixins],
  props: {
    data: Object,
  },
}
</script>