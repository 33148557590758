<template>

  <div class="">
    <div v-if="appearance.layout == 'layout-1'" class="mx-auto w-full h-full">

        <!--   TODO: limit!   -->
        <swiper
            class="relative w-full pt-2 px-2 -mx-2 -mt-2 pb-3"
            :slides-per-view="1.15"
            :space-between="15"
            :breakpoints="{
              640: {
                slidesPerView: 2.25,
                spaceBetween: 25,
              },
              1200: {
                slidesPerView: 3.25,
                spaceBetween: 25,
              }
            }"
        >
          <swiper-slide v-for="(event, index) in data.json" :key="index" class="h-auto group">
            <section class="h-full flex flex-col shadow-sw-1 bg-white relative">

              <div class="flex-shrink-0" v-if="event.images[0]">
                <router-link :to="{ name: 'eventDetail', params: { slug: sanitizeString(event.slug), lang: twoLetterIsoCode, event: event, id: event.uid, urlback :slugPage() }}">
                  <img class="min-h-56 h-56 md:max-h-min w-full object-cover group-hover:opacity-75 transition-all duration-200 ease-in-out" :src="'https://res.cloudinary.com/dgr25kh64/image/fetch/c_fill,f_auto,h_324,q_80,w_576/' + event.images[0].detail_picture_url" :alt="event.name">
                </router-link>
              </div>

              <div class="flex-1 px-4 pt-4 pb-4 flex flex-col justify-between">

                <div class="flex-1">
                  <router-link class="block"
                               :to="{ name: 'eventDetail', params: { slug: sanitizeString(event.slug), lang: twoLetterIsoCode, event: event, id: event.uid, urlback :slugPage()  }}">

                    <div class="rounded-md bg-sw-gray-light px-3 py-1 inline-flex items-center mb-3" v-if="event.mountains">
                      <svg class="w-3.5 h-3.5 mr-2 text-sw-primary">
                        <use xlink:href="#icon--mountain"></use>
                      </svg>
                      <p class="uppercase mb-0">{{event.mountains}}</p>
                    </div>

                    <h3 class="block text-xl font-headline font-bold sm:text-2xl sm:leading-7">
                      {{ event.name }}
                    </h3>
                    <p class="text-md font-sans my-2">
                      {{ event.start }}
                      <span v-if="event.start != event.end"> - {{ event.end }}</span>
                      <span v-if="event.startTime"> | {{ event.startTime }}</span><span v-if="event.endTime"> - {{ event.endTime }}</span>
                    </p>
                  </router-link>
                </div>

                <div class="mt-1">
                  <router-link :to="{ name: 'eventDetail', params: { slug: sanitizeString(event.slug), lang: twoLetterIsoCode, event: event, id: event.uid, urlback :slugPage()  }}" class="group-hover:text-gray-500 group-hover:underline transition-all duration-200 ease-in-out text-sw-primary font-black uppercase">
                    {{ $t('labels.more') }} →</router-link>
                </div>

              </div>
            </section>
          </swiper-slide>
          <swiper-slide class="h-auto">
            <section class="h-full flex flex-col shadow-sw-1 bg-white relative group">
              <a href="https://www.corvatsch-diavolezza.ch/aktuelles/events" target="_blank" class="flex-1 px-4 pt-4 pb-4 flex flex-col justify-center items-center">
                  <svg class="leading-none text-gray-500 w-100 h-100">
                    <use xlink:href="#icon--arrow-circle"></use>
                  </svg>
                  <p class="block font-headline font-bold text-xl sm:text-2xl sm:leading-7 mt-4 group-hover:text-gray-500 group-hover:underline transition-all duration-200 ease-in-out">
                    {{ $t('labels.show-all') }}</p>
              </a>
            </section>
          </swiper-slide>
        </swiper>

    </div>


    <div v-else class="mx-auto grid gap-5 md:grid-cols-2 lg:grid-cols-3">

      <section class="flex flex-col shadow-sw-1 bg-white overflow-hidden relative group" v-for="(event, index) in data.json"
           :item="index" :key="index" >
        <div class="flex-shrink-0" v-if="event.images[0]">
          <router-link :to="{ name: 'eventDetail', params: { slug: sanitizeString(event.slug), lang: twoLetterIsoCode, event: event, id: event.uid, urlback :slugPage()  }}">
            <img class="min-h-56 h-56 w-full object-cover group-hover:opacity-75 transition-all duration-200 ease-in-out" :src="'https://res.cloudinary.com/dgr25kh64/image/fetch/c_fill,f_auto,h_324,q_80,w_576/' + event.images[0].detail_picture_url" :alt="event.name">
          </router-link>
        </div>
        <div class="flex-1 px-4 pt-2 pb-4 flex flex-col justify-between">

          <div class="flex-1">
            <router-link class="block"
                         :to="{ name: 'eventDetail', params: { slug: sanitizeString(event.slug), lang: twoLetterIsoCode, event: event, id: event.uid, urlback :slugPage()  }}">
              <div class="rounded-md bg-sw-gray-light px-3 py-1 inline-flex items-center mb-3"  v-if="event.mountains">
                <svg class="w-3.5 h-3.5 mr-2 text-sw-primary">
                  <use xlink:href="#icon--mountain"></use>
                </svg>
                <p class="uppercase mb-0">{{event.mountains}}</p>
              </div>
              <h3 class="block text-xl font-headline font-bold sm:text-3xl">
                {{ event.name }}
              </h3>
              <p class="text-lg font-headline font-bold my-2">
                {{ event.start }}
                <span v-if="event.start != event.end"> - {{ event.end }}</span>
                <span v-if="event.startTime"> | {{ event.startTime }}</span><span v-if="event.endTime"> - {{ event.endTime }}</span>
              </p>
            </router-link>
          </div>

          <div class="mt-1">
            <router-link :to="{ name: 'eventDetail', params: { slug: sanitizeString(event.slug), lang: twoLetterIsoCode, event: event, id: event.uid, urlback :slugPage()  }}" class="group-hover:text-gray-500 group-hover:underline transition-all duration-200 ease-in-out text-sw-primary font-black uppercase">
              {{ $t('labels.more') }}  →</router-link>
          </div>

        </div>
      </section>
      <section class="flex flex-col shadow-sw-1 bg-white overflow-hidden relative py-20 group">
        <a href="https://www.corvatsch-diavolezza.ch/aktuelles/events" target="_blank" class="flex-1 px-4 pt-4 pb-4 flex flex-col justify-center items-center">
          <svg class="leading-none text-gray-300 w-100 h-100">
            <use xlink:href="#icon--arrow-circle"></use>
          </svg>
          <p class="block font-headline font-bold text-xl sm:text-2xl sm:leading-7 mt-4 group-hover:text-gray-500 group-hover:underline transition-all duration-200 ease-in-out">
            {{ $t('labels.show-all') }}</p>
        </a>
      </section>
    </div>
  </div>

</template>

<script>

import Mixins from "@/Mixins";
import 'swiper/swiper-bundle.css';
import { Swiper, SwiperSlide } from 'swiper/vue';

export default {
  name: 'AllEvents',
  mixins: [Mixins],
  props: {
    languages: Object,
    filters: Object,
    data: Object,
    appearance: Object,
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      twoLetterIsoCode: null
    }
  },
  methods: {
    slugPage : function(){
      return window.location.pathname;
    },
    sanitizeString: function (title) {
        return  title.replace('/', '');
    },
    sanitizeStringOld: function (title) {
      var slug = "";
      var titleLower = title.toLowerCase();
      slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e');
      slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a');
      slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o');
      slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u');
      slug = slug.replace(/đ/gi, 'd');
      slug = slug.replace(/\s*$/g, '');
      slug = slug.replace(/\s+/g, '-');
      return slug;
    }
  },
  computed: {
    formattedDate() {
      return this.formatDate(this.date);
    }
  },
  created() {
    let that = this;
    this.languages.forEach(function (lang) {
      if(lang.active == 1 && lang.twoLetterIsoCode != 'de' ){
        that.twoLetterIsoCode = lang.twoLetterIsoCode;
      }
    });
  }
}
</script>
