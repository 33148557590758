<template>

  <div v-if="content.subheader">
    <h2 :class="[content.headerPosition != '' ? 'text-' + content.headerPosition : '' ]" class="block text-base font-headline font-bold tracking-wide uppercase" v-if="content.headerLayout == 1">{{ content.subheader }}</h2>
    <h3 :class="[content.headerPosition != '' ? 'text-' + content.headerPosition : '' ]" class="block text-base font-headline font-bold tracking-wide uppercase" v-if="content.headerLayout == 2 || content.headerLayout == 0">{{ content.subheader }}</h3>
    <h4 :class="[content.headerPosition != '' ? 'text-' + content.headerPosition : '' ]" class="block text-base font-headline font-bold tracking-wide uppercase" v-if="content.headerLayout == 3">{{ content.subheader }}</h4>
    <h5 :class="[content.headerPosition != '' ? 'text-' + content.headerPosition : '' ]" class="block text-base font-headline font-bold tracking-wide uppercase" v-if="content.headerLayout == 4">{{ content.subheader }}</h5>
    <h6 :class="[content.headerPosition != '' ? 'text-' + content.headerPosition : '' ]" class="block text-base font-headline font-bold tracking-wide uppercase" v-if="content.headerLayout == 5">{{ content.subheader }}</h6>
  </div>

</template>

<script>
export default {
  name: 'SubHeader',
  props: {
    content: Object,
  },
}
</script>