<template>

  <div>
    <div class="grid-container">
      <div class="grid-x grid-padding-x">
        <div class="small-12 cell">
          <p class="text-xl md:text-2xl font-headline leading-8 uppercase mb-2 md:mb-6">
            {{ title }}
          </p>
        </div>
      </div>
    </div>
    <div>
      <div class="grid grid-cols-12 gap-4">
        <div class="col-span-12 md:col-span-5 lg:col-span-3 border-b border-gray-200 pb-4 mb-2 md:border-b-0 md:border-r md:pb-0 md:mb-0 md:pr-2">
          <div>
            <div>
              <p class="font-headline text-xl md:mb-6" v-if="day1Date == day2Date">{{ $t('labels.morning') }} {{formatDateWeather(day1Date)}}</p>
              <p class="font-headline text-xl md:mb-6" v-else>{{ formatDateWeather(day1Date) }}</p>
            </div>
            <div class="flex items-center space-x-8">
              <div>
                <img class="max-w-full w-20 h-auto" :src="'https://www.corvatsch-diavolezza.ch/typo3conf/ext/spot_corvatsch/Resources/Public/Assets/Images/WeatherIcons/icon' + day1Icon + '.svg'">
              </div>
              <div>
                <div>{{ $t('labels.min') }}</div>
                <div class="text-xl md:text-2xl lg:text-xl font-headline">
                  {{ day1Min }} °C
                </div>
              </div>
              <div>
                <div>{{ $t('labels.max') }}</div>
                <div class="text-xl md:text-2xl lg:text-xl font-headline">
                  {{ day1Max }} °C
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6 lg:col-span-3 md:pr-2">
          <div class="weather-quick ">
            <div>
              <p class="font-headline text-xl md:mb-6" v-if="day1Date == day2Date">{{ $t('labels.afternoon') }} {{formatDateWeather(day2Date)}}</p>
              <p class="font-headline text-xl md:mb-6" v-else>{{ formatDateWeather(day2Date) }}</p>
            </div>
            <div class="flex items-center space-x-8">
              <div>
                <img class="max-w-full w-20 h-auto" :src="'https://www.corvatsch-diavolezza.ch/typo3conf/ext/spot_corvatsch/Resources/Public/Assets/Images/WeatherIcons/icon' + day2Icon + '.svg'">
              </div>
              <div>
                <div>{{ $t('labels.min') }}</div>
                <div class="text-xl md:text-2xl lg:text-xl font-headline">
                  {{ day2Min }} °C
                </div>
              </div>
              <div>
                <div>{{ $t('labels.max') }}</div>
                <div class="text-xl md:text-2xl lg:text-xl font-headline">
                  {{ day2Max }} °C
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-span-12 lg:col-span-6">
          <p class="mb-0">
            {{ text }}
          </p>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import Mixins from "@/Mixins";

export default {
  name: 'Weather',
  props: {
    dayOdd: Number,
    title: String,
    text: String,
    day1Date: Date,
    day1DateDiff: String,
    day1Icon: Number,
    day1Min: String,
    day1Max: String,
    day2Date: Date,
    day2DateDiff: String,
    day2Icon: Number,
    day2Min: String,
    day2Max: String,
  },
  mixins: [Mixins]
}
</script>