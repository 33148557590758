<template>

  <div class="relative">
    <div class="absolute -top-10 right-0 ">
      <div class="flex justify-end">
        {{currentDate()}}
      </div>
    </div>
    <div class="w-full grid gap-2 lg:gap-4 " :class="singleMountain == false ? 'md:grid-cols-2' : 'lg:grid-cols-3'">
      <a v-bind:href="data.jsonLiveStatusInfoSnow.pageDetails" class="bg-sw-gray-light px-4 py-2 rounded-2xl text-left flex items-center">
        <div class="w-12 h-12 mr-6 text-sw-green">
          <div class="sw_percentage-circle">
            <div class="sw_percentage-circle-text">
              <svg class="h-5 w-5" :class="data.jsonLiveStatusInfoSnow.facilities.numberOfOpenFacilities>0 ? 'text-sw-gray-dark' :  'text-sw-red'" >
                <use xlink:href="#icon--facilities"></use>
              </svg>
            </div>
            <div v-if = "data.jsonLiveStatusInfoSnow.facilities.numberOfOpenFacilities>0" class="pie" :class="'p' + percentage(data.jsonLiveStatusInfoSnow.facilities.numberOfOpenFacilities, data.jsonLiveStatusInfoSnow.facilities.numberOfFacilities)"></div>
            <div class="pie p200 pie-closed" v-else ></div>
          </div>
        </div>
        <div>
          <div class="flex space-x-6">
            <p class="mb-0"><b>{{ data.jsonLiveStatusInfoSnow.facilities.numberOfOpenFacilities }}</b>&nbsp;&nbsp;von&nbsp;&nbsp;<b>{{ data.jsonLiveStatusInfoSnow.facilities.numberOfFacilities }}</b></p>
          </div>
          <p class="mb-0">{{ $t('labels.open-plants') }}</p>
        </div>
      </a>
      <template v-if = "season == 3">
        <a v-if = "data.jsonLiveStatusInfoSnow.slopes && data.jsonLiveStatusInfoSnow.slopes.numberOfSlopes>0" v-bind:href="data.jsonLiveStatusInfoSnow.pageDetails + '?id=1'" class="bg-sw-gray-light px-4 py-2 rounded-2xl text-left flex items-center">
        <div class="w-12 h-12 mr-6 text-sw-green">
          <div class="sw_percentage-circle">
            <div class="sw_percentage-circle-text">
              <svg class="h-5 w-5" :class="data.jsonLiveStatusInfoSnow.slopes.numberOfOpenSlopes>0 ? 'text-sw-gray-dark' :  'text-sw-red'" >
                <use xlink:href="#icon--slopes"></use>
              </svg>
            </div>
            <div v-if = "data.jsonLiveStatusInfoSnow.slopes.numberOfOpenSlopes>0"  class="pie" :class="'p' + percentage(data.jsonLiveStatusInfoSnow.slopes.numberOfOpenSlopes, data.jsonLiveStatusInfoSnow.slopes.numberOfSlopes)"></div>
            <div class="pie p200 pie-closed" v-else ></div>
          </div>
        </div>
        <div>
          <div class="flex space-x-6">
            <p class="mb-0"><b>{{ data.jsonLiveStatusInfoSnow.slopes.numberOfOpenSlopes }}</b>&nbsp;&nbsp;von&nbsp;&nbsp;<b> {{ data.jsonLiveStatusInfoSnow.slopes.numberOfSlopes }}</b></p>
          </div>
          <p class="mb-0">{{ $t('labels.open-slopes') }}</p>
        </div>
      </a>
      </template>

      <template v-if = "season == 2">
        <a v-if = "data.jsonLiveStatusInfoSnow.diverses && data.jsonLiveStatusInfoSnow.diverses.numberOfDiverses>0" v-bind:href="data.jsonLiveStatusInfoSnow.pageDetails + '?id=1'" class="bg-sw-gray-light px-4 py-2 rounded-2xl text-left flex items-center">
          <div class="w-12 h-12 mr-6 text-sw-green">
            <div class="sw_percentage-circle">
              <div class="sw_percentage-circle-text">
                <svg class="h-5 w-5" :class="data.jsonLiveStatusInfoSnow.diverses.numberOfOpenDiverses>0 ? 'text-sw-gray-dark' :  'text-sw-red'" >
                  <use xlink:href="#icon--trails"></use>
                </svg>
              </div>
              <div v-if = "data.jsonLiveStatusInfoSnow.diverses.numberOfOpenDiverses>0"  class="pie" :class="'p' + percentage(data.jsonLiveStatusInfoSnow.diverses.numberOfOpenDiverses, data.jsonLiveStatusInfoSnow.diverses.numberOfDiverses)"></div>
              <div class="pie p200 pie-closed" v-else ></div>
            </div>
          </div>
          <div>
            <div class="flex space-x-6">
              <p class="mb-0"><b>{{ data.jsonLiveStatusInfoSnow.diverses.numberOfOpenDiverses }}</b>&nbsp;&nbsp;von&nbsp;&nbsp;<b> {{ data.jsonLiveStatusInfoSnow.diverses.numberOfDiverses }}</b></p>
            </div>
            <p class="mb-0">{{ $t('labels.open-diverses') }}</p>
          </div>
        </a>
      </template>

      <div v-for="(mountain, index) in weather" :key="index">
        <a v-bind:href="linkWeatherDetail(mountain.name)" class="bg-sw-gray-light px-4 py-2 rounded-2xl text-left flex items-center" >
          <div class="w-12 h-12 flex justify-center items-center mr-6">
            <img class="max-w-full w-20 h-auto" v-if="mountain.icon" :src="'https://www.corvatsch-diavolezza.ch/' + mountain.icon">
          </div>
          <div>
            <div class="flex space-x-6">
              <p class="mb-0" v-if="mountain.temperatures.min">Min&nbsp;&nbsp;<b>{{mountain.temperatures.min}}°C</b></p>
              <p class="mb-0" v-if="mountain.temperatures.max">Max&nbsp;&nbsp;<b>{{mountain.temperatures.max}}°C</b></p>
            </div>
            <p class="mb-0 capitalize" v-if="mountain.name">{{ mountain.name }}</p>
          </div>
        </a>
      </div>

    </div>

  </div>

</template>

<script>

import Mixins from "@/Mixins";

export default {
  name: 'Livestatus',
  mixins: [Mixins],
  props: {
    data: Object,
    season: String
  },
  data() {
    return {
      weather: [],
      singleMountain: true,
    }
  },
  methods: {
    currentDate() {
      const current = new Date();
      return `${current.getDate()}.${current.getMonth()+1}.${current.getFullYear()}`;
    },
    linkWeatherDetail(mountain){
      if (this.weather.length>1 && mountain==='diavolezza'){
          return this.data.jsonLiveStatusWeather.pageDetails2;
      }
      return this.data.jsonLiveStatusWeather.pageDetails
    }
  },
  mounted() {
    var mountains = null;
    var jsonWeatherToday = this.data.jsonLiveStatusWeather.today;
    var currentTime = new Date().getHours();
    mountains = this.data.json.stations;

    if(this.data.json.stations.length === 1) {

      let mountain= mountains[0];
      if (mountain === 'diavolezza-lagalb') mountain = 'diavolezza';
      if (currentTime >= 12) {
        this.weather.push({
          name: mountain,
          icon: jsonWeatherToday.icons.afternoon,
          temperatures: jsonWeatherToday.temperatures[mountain]['afternoon']
        });
      } else {
        this.weather.push({
          name: mountain,
          icon: jsonWeatherToday.icons.morning,
          temperatures: jsonWeatherToday.temperatures[mountain]['morning']
        });
      }
    } else {
      this.singleMountain = false;
      if (currentTime >= 12) {
        let array = Object.entries(jsonWeatherToday.temperatures);
        let that = this;
        array.forEach(function(mountain) {
          that.weather.push({
            name: mountain[0],
            icon: jsonWeatherToday.icons.afternoon,
            temperatures: mountain[1]['afternoon']
          });
        });
      } else {
        let array = Object.entries(jsonWeatherToday.temperatures);
        let that = this;
        array.forEach(function(mountain) {
          that.weather.push({
            name: mountain[0],
            icon: jsonWeatherToday.icons.morning,
            temperatures: mountain[1]['morning']
          });
        });
      }

    }

  },
}
</script>
