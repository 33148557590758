<template>

  <div class="sw_intro" v-if="!isClicked">

    <div class="fixed z-60 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="flex justify-center min-h-screen h-full w-full text-center">
        <div class="fixed inset-0 bg-black transition-opacity bg-center bg-cover" :style="styleBackgroundImage"  aria-hidden="true"></div>

        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

        <div class="w-full h-full text-left transform transition-all pb-4 sm:align-middle">
          <div class="absolute top-0 right-0 pt-4 xs:pt-8 pr-4 z-10">
            <button v-on:click="closeModal" type="button" class="text-white hover:opacity-50 focus:outline-none">
              <span class="sr-only">Close</span>
              <svg class="h-6 w-6">
                <use xlink:href="#icon--x"></use>
              </svg>
            </button>
          </div>

          <swiper :slides-per-view="1"
                  :space-between="0"
                  :pagination="{ clickable: true, el: '.sw_swiper-pagination' }"
                  :navigation="{ nextEl: '.sw_swiper-button-next' }"
                  :modules="[Virtual]"
                  :virtual="true"
                  class="relative pb-10 h-full w-full text-white text-center">

            <div class="w-full font-sans absolute z-10 bottom-2.5 px-4 flex justify-between">
              <span v-on:click="closeModal">{{ $t('labels.skip') }}</span>
              <div class="swiper-pagination sw_swiper-pagination left-2/4 transform -translate-x-2/4" slot="pagination"></div>
              <span class="sw_swiper-button-next">{{ $t('labels.further') }} →</span>
            </div>

            <swiper-slide
                class="h-full w-full pt-24 pb-4 px-4 xs:px-8 overflow-y-scroll"
                v-for="(content, index) in navigation"
                :key="index"
                :virtualIndex="index">
              <div class="max-w-3xl mx-auto sm:py-8 flex flex-col items-center">
                <ImageComponent :image="content.content.media[0]" v-if="content.content.media[0]" class="w-32 h-auto max-w-full mb-2"/>
                <div class="mt-4 space-y-4">
                  <h3 class="text-3xl font-headline font-bold">
                    {{ content.content.header }}
                  </h3>
                  <div v-html="content.content.bodytext"></div>
                </div>
                <Link v-on:click="closeModal" :style="1" :text="content.content.headerLink.title" :link="content.content.headerLink.url" :class="'mt-4'" :linkClass="'text-gray-900'" v-if="content.content.headerLink != ''"/>
              </div>
            </swiper-slide>

          </swiper>

        </div>
      </div>
    </div>
  </div>

</template>

<script>


import 'swiper/swiper-bundle.css';
import { Swiper, SwiperSlide } from 'swiper/vue';
import Link from "@/components/contents/partials/Link";
import ImageComponent from "@/components/contents/partials/Image";
import SwiperCore, {
  Virtual,
  Navigation,
  Pagination
} from 'swiper';

SwiperCore.use([Navigation, Pagination, Virtual]);

export default {
  name: 'Intro',
  props: {
    navigation: Object,
    season : String
  },
  data() {
    return {
      isClicked: false,
      styleBackgroundImage :{
        backgroundImage : 'url(/images/intro-background'+this.season+'.jpg)'
      }
    }
  },
  components: {
    Link,
    ImageComponent,
    Swiper,
    SwiperSlide
  },
  methods: {
    closeModal: function() {
      this.isClicked = !this.isClicked;
      this.isClicked ? document.body.classList.remove("overflow-hidden") : document.body.classList.add("overflow-hidden");
      localStorage.setItem('introClosed', true);
    },
  },
}
</script>