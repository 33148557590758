import { createWebHistory, createRouter } from 'vue-router';
import EventDetail from '../components/integrations/detail/Event.vue'

const routes = [
    {
        path: '/:lang?/events/:slug',
        name: 'eventDetail',
        component: EventDetail,
        props: true
    },
    /*{
        path: "/:catchAll(.*)",
        component: NotFound,
    },*/
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
