<template>

  <div>

    <div v-if="appearance.layout == 'default'">
      <div class="space-y-2 md:space-y-6">
        <div  class="space-y-2 md:space-y-6" v-if="content.subheader != '' || content.header != ''">
          <Headers :content="content" />
          <Link :style="content.linkStyle" :text="content.linkText" :link="content.headerLink.url" :target="content.headerLink.target" v-if="content.headerLink != ''"/>
        </div>
        <a :href="content.headerLink.url" :target="content.headerLink.target" v-if="content.headerLink != ''">
          <Media :content="content.gallery" imgClass="w-full h-full object-cover"/>
        </a>
        <Media :content="content.gallery" imgClass="w-full h-full object-cover" v-else/>
      </div>
    </div>

    <div v-else-if="appearance.layout == 'layout-1'">
      <div class="-mx-4 sm:-mx-6 lg:mx-0">
        <div class="relative overflow-hidden bg-black">
          <div class="absolute inset-0 bg-cover bg-center" :style="{'background-image': 'url(' + content.media[0].publicUrl + ')'}" v-if="content.media[0]"></div>
          <div class="relative py-14 px-4 sm:py-24 sm:px-6 lg:px-32">
            <div class="relative max-w-3xl mx-auto flex flex-col items-center">
              <ImageComponent class="w-20 h-auto max-w-full mb-4" :image="content.media[1]" v-if="content.media[1]"/>
              <Headers class="text-white text-shadow" :content="content" />
              <Link :style="0" :text="content.headerLink.title" :link="content.headerLink.url" :target="content.headerLink.target" v-if="content.headerLink != ''"/>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import Headers from "@/components/contents/partials/Headers";
import Link from "@/components/contents/partials/Link";
import Media from "@/components/contents/partials/Media";
import ImageComponent from "@/components/contents/partials/Image";

export default {
  name: 'Image',
  components: {
    Headers,
    Link,
    Media,
    ImageComponent
  },
  props: {
    content: Object,
    appearance: Object
  },
}
</script>
