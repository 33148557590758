<template>

  <div>
    <div v-if="appearance.layout == 'default'">
      <div class="grid grid-cols-12 gap-8">
        <div class="col-span-6 col-start-3 md:col-span-6 md:col-start-auto lg:col-span-4">
          <ImageComponent class="w-full h-auto"  :image="content.media[0]" v-if="content.media[0]"/>
        </div>
        <div class="col-span-12 md:col-span-6 lg:col-span-8">
          <Headers :content="content" />
          <Bodytext :content="content" v-if="content.bodytext"/>
          <Link :style="10" :text="content.headerLink.title" :link="content.headerLink.url" v-if="content.headerLink != '' && content.headerLink.title  != ''"/>
        </div>
      </div>
    </div>

    <div v-else-if="appearance.layout == 'layout-1'">
      <a :href="content.headerLink.url" class="py-8 lg:py-10">
        <div class="relative px-4">
          <div class="absolute inset-0 overflow-hidden opacity-50">
            <ImageComponent :image="content.media[0]" v-if="content.media[0]" imgClass="w-full h-full object-center object-cover"/>
          </div>
          <div aria-hidden="true"
               class="absolute inset-0 z-10 pointer-events-none mix-blend-multiply bg-sw-primary"></div>
          <div class="relative mx-auto max-w-7xl z-10 pt-20 pb-6 md:pt-36">
            <div class="space-y-2 text-white">
              <Headers :content="content" />
              <Bodytext :content="content" v-if="content.bodytext"/>
              <Link :style="11" :text="content.headerLink.title" :link="content.headerLink.url" :target="content.headerLink.target" v-if="content.headerLink != '' && content.headerLink.title != ''"/>
            </div>
          </div>
        </div>
      </a>
    </div>

    <div v-else-if="appearance.layout == 'layout-2'">
      <div class="relative flex space-x-4 md:space-x-6">
        <div class="flex-shrink-0">
          <ImageComponent class="w-12 h-12 md:h-24 md:w-24"  :image="content.media[0]" v-if="content.media[0]"/>
        </div>
        <div class="flex-1 min-w-0">
          <Headers :content="content" />
          <Bodytext :content="content" v-if="content.bodytext"/>
          <Link :style="10" :text="content.headerLink.title" :link="content.headerLink.url" v-if="content.headerLink != '' && content.headerLink.title  != ''"/>
        </div>
      </div>

    </div>

  </div>
</template>

<script>

import Headers from "@/components/contents/partials/Headers";
import Bodytext from "@/components/contents/partials/Bodytext";
import Link from "@/components/contents/partials/Link";
import ImageComponent from "@/components/contents/partials/Image";

export default {
  name: 'Textpic',
  components: {
    Headers,
    Bodytext,
    Link,
    ImageComponent
  },
  props: {
    content: Object,
    appearance: Object
  },
}
</script>