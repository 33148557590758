<template>

  <div>
    <img
        :class="[imgClass]"
        class="h-full w-full object-cover"
        :src="image.publicUrl" :title="image.properties.title" :alt="image.properties.alternative"
        :width="image.properties.dimensions.width" :height="image.properties.dimensions.height">
    <figcaption v-if="image.properties.description != null" class="mt-2 text-left">
      {{ image.properties.description }}
    </figcaption>
  </div>

</template>

<script>
export default {
  name: 'Image',
  props: {
    image: Object,
    imgClass: String,
  },
}
</script>
