<template>
  <div>

    <div class="mt-8">

      <div class="w-full relative" style="padding-top: 56.25%" v-if="data.panorama == true">
        <iframe class="absolute inset-0 w-full h-full" :src="'https://webtv.feratel.com/webtv/?design=v3&cam=4210&lg='+lang" width="100%"  scrolling="no" noresize="noresize" frameborder="no" allowtransparency="true"></iframe>
      </div>

      <div class="d-block py-6 md:py-10"></div>

      <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
         <div class="shadow-lg" v-for="webcam in data.json" :key="webcam.uid" @click="() => showImg(webcam.uid)">
           <div class="w-full min-h-56 h-56 overflow-hidden lg:aspect-none">
             <img :src="webcam.thumbnail+'?'+getRandomInt()" alt=""  class="w-full h-full object-center object-cover">
           </div>
           <div class="flex justify-between bg-white px-4 py-4">
            <div>
              <div class="flex items-center mb-3">
                <svg class="w-3.5 h-3.5 mr-2 text-sw-primary">
                  <use xlink:href="#icon--mountain"></use>
                </svg>
                <p class="font-bold text-lg mb-0">{{webcam.mountain.name}}</p>
              </div>
              <p class="text-2xl leading-7 font-headline font-bold uppercase">{{webcam.name}}</p>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!--
    <vue-easy-lightbox
        :visible="visible"
        :imgs="imgs"
        :index="index"
        @hide="handleHide">

      <template v-slot:toolbar></template>

    </vue-easy-lightbox>
    -->

  </div>
</template>

<script>
//import VueEasyLightbox from 'vue-easy-lightbox'

export default {
  name: 'Webcams',
  props: {
    data: Object
  },
  components: {
    //VueEasyLightbox
  },
  data() {
    return {
      lang : 'de',
      imgs: [],
      index: 0,
      visible: false,
      maxRandom : 9999,
      minRandom : 1000
    }
  },
  mounted() {
    this.data.json.forEach(webcam => {
      this.imgs.push(
          {
            title: webcam.name,
            src: webcam.image,
          }
      )
    });
  },
  methods: {
    showImg (index) {
      this.index = index
      this.visible = true
    },
    handleHide () {
      this.visible = false
    },
    getRandomInt() {
      return Math.floor(Math.random() * (this.maxRandom - this.minRandom) + this.minRandom);
    }

  }
}
</script>
