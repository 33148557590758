<template>

  <div class="space-y-8">
    <ul class="tabs" aria-label="Tabs">
      <li v-for='(tab, index) in tabs' :key='tab.title'  :class="[index == selectedIndex ? 'is-active' : '']" class="tabs-title" aria-current="page">
        <a @click='selectTab(index)'>
          <svg class="icon w-6 h-6"><use :xlink:href="'#icon--' + tab.icon"></use></svg>
          <span class="tabs-word capitalize">
              {{ tab.title }}
          </span>
        </a>
      </li>
    </ul>
    <div class="tabs-content w-full">
      <slot></slot>
    </div>
  </div>

</template>

<script>
export default {
  props: {
    mode: {
      type: String,
    }
  },
  data () {
    return {
      selectedIndex: null, // the index of the selected tab,
      tabs: []         // all of the tabs
    }
  },
  mounted () {
    if(this.$route.query.id) {
      this.selectTab(parseInt(this.$route.query.id))
    } else {
      this.selectTab(0)
    }
  },
  methods: {
    selectTab (i) {
      this.selectedIndex = i

      // loop over all the tabs
      if(this.tabs){
        this.tabs.forEach((tab, index) => {
          tab.isActive = (index === i)
        })
      }
    }
  }
}

</script>
