<template>

  <div v-if="data.diverses">

    <div class="toggle-bg-color" v-for="(diverses, index) in data.diverses.list" :key="index">

      <div class="accordion-content px-4 py-4 md:py-8" >
        <div class="grid grid-cols-12 gap-1 md:gap-4">
          <div class="col-auto md:col-span-1 flex items-center justify-center" v-if="diverses.TypID">
            <div :class="diverses.StatusID == 1 || diverses.StatusID == 8  ? 'text-sw-green' : 'text-sw-red'">
              <svg class="w-6 h-6 md:mx-auto"><use :xlink:href="'#' + $t('labels.type-icon-' + diverses.TypID)"></use></svg>
            </div>
          </div>
          <div class="col-span-6 md:col-span-3" v-if="diverses.TypID">
            <p class="mb-0 pl-2 md:pl-0">
              {{diverses.Name}}<br>
            </p>
          </div>
          <div class="col-span-4 md:col-span-2 font-black" :class="diverses.StatusID == 1 || diverses.StatusID == 8 ? 'text-sw-green' : 'text-sw-red'" v-if="diverses.StatusID">
            <p class="text-right md:text-left todo-color">{{ $t('labels.diverses-status-' + diverses.StatusID) }}</p>
          </div>

          <div class="col-span-12 md:col-span-8 lg:col-span-4 xxl:col-span-4">
            <template v-if="diverses.timetable">
            <br class="md:hidden">
            <div class="flex" v-if="diverses.timetable">
              <div class="mr-4">
                <svg class="icon icon--calendar w-5 h-5"><use xlink:href="#icon--calendar"></use></svg>
              </div>
              <div class="icon-wrap__text">
                <p class="mb-1" v-if="diverses.timetable.days.length == 7">
                  {{ $t('labels.daily') }}
                </p>
                <p class="mb-1" v-else v-for="(day, index) in diverses.timetable.days" :key="index">
                  {{ $t('labels.day-' + day) }}<span v-if="index != diverses.timetable.days.length - 1">, </span>
                </p>
                <span class="text-sm text-gray-400">
                  <span v-if="diverses.timetable.time">{{ truncate(diverses.timetable.time, 30, '...') }}</span><br>
                </span>
              </div>

            </div>
            </template>
          </div>

          <!--
          <div class="col-span-12 md:col-span-4 md:col-start-4 lg:col-span-2 lg:col-start-auto xxl:col-span-2">
            <br class="lg:hidden">
            <div class="flex items-center">
            </div>
          </div>
          -->
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import Mixins from "@/Mixins";
import $ from "jquery";

export default {
  name: 'Lifts',
  mixins: [Mixins],
  props: {
    data: Object,
  },
  mounted () {
    let that = this;
    that.timeSchedule = $('.infosnow-time-schedule');
  },
  methods: {
  },
}
</script>
