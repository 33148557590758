<template>

  <div v-if="data.slopes">

    <div class="accordion-content px-4 py-4 md:py-8" >
      <table class="table-fixed w-full table--header-top">
        <thead>
          <tr class="bg-sw-gray-light">
            <th scope="col" class="px-6 py-3 text-left text-lg font-bold">{{ $t('labels.nr') }}</th>
            <th scope="col" class="px-6 py-3 text-left text-lg font-bold">{{ $t('labels.name') }}</th>
            <th scope="col" class="px-6 py-3 text-left text-lg font-bold">{{ $t('labels.status') }} </th>
            <th scope="col" class="px-6 py-3 text-left text-lg font-bold">{{ $t('labels.condition') }}</th>
            <th scope="col" class="px-6 py-3 text-left text-lg font-bold">{{ $t('labels.type') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr class="toggle-bg-color py-2 block md:table-row md:py-0" v-for="(slope, index) in data.slopes.list" :key="index">
            <td v-if="slope.Initial" class="px-4 md:px-6 sm:py-4" data-mobile="Nr" >{{ slope.Initial }}</td>
            <td v-if="slope.Name" class="px-4 md:px-6 sm:py-4" data-mobile="Name" >{{ slope.Name }}</td>
            <td class="px-4 md:px-6 sm:py-4 font-black" :class="slope.StatusID == 8 ? 'text-sw-green' : 'text-sw-red'" data-mobile="Status" >{{ $t('labels.slope-status-' + slope.StatusID) }}</td>
            <td class="px-4 md:px-6 sm:py-4" data-mobile="Zustand" >{{ $t('labels.slope-slf-status-' + slope.SLFStatusID) }}</td>
            <td class="px-4 md:px-6 sm:py-4" data-mobile="Typ" ><span class="w-4 h-4 inline-block rounded-full mr-2" :class="'bg-sw-color-' + slope.TypID"></span>{{ $t('labels.slope-type-' + slope.TypID) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

</template>

<script>
export default {
  name: 'Slopes',
  props: {
    data: Object,
  },
}
</script>