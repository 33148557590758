<template>
  <div class="accordion-item">
    <div class="accordion-title py-4">
      <div class="grid grid-cols-12 gap-4 items-center">
        <div class="col-span-12 md:col-span-1 hidden md:block">
          <div class="status text-center">
            <svg class="w-6 h-6 icon icon--open" :class="status == true ? 'text-sw-green' : 'text-sw-red'"><use xlink:href="#icon--open"></use></svg>
          </div>
        </div>
        <div class="col-span-8 md:col-span-3" v-if="name">
          <div class="font-bold text-lg">
            {{name}}
          </div>
        </div>
        <div class="col-span-4 md:col-span-2">
          <div class="status text-right md:text-left">
            <span class="text-sw-green font-black" v-if="status == true">{{ $t('labels.open') }}</span>
            <span class="text-sw-red font-black" v-else>{{ $t('labels.closed') }}</span>
          </div>
        </div>
        <div class="col-span-4 md:col-span-2 xl:col-span-3">
          <div class="flex items-center" v-if="info">
            <svg class="w-6 h-6 icon mr-4"><use :xlink:href="'#' + icon"></use></svg>
            <div class="icon-wrap__text status font-black" :class="status == true ? 'text-sw-green' : 'text-sw-red'">
              <span>{{ info }}</span>
            </div>
          </div>
        </div>
        <div class="col-span-8 md:col-span-4 xl:col-span-3">
          <div class="flex justify-end items-center">
            <Link :style="3" :text="$t('labels.interactive-map')" class="-mt-2 w-36 md:w-auto" :link="link" v-if="link != null" target="_blank"/>
            <button class="button button--accordeon ml-2 md:ml-8 w-8 h-8 rounded-full bg-white md:bg-transparent flex items-center justify-center">
              <svg class="w-4 h-4 icon icon--chevron-down"><use xlink:href="#icon--chevron-down"></use></svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Link from "@/components/contents/partials/Link";

export default {
  name: "InfosnowItem",
  props: ["status", "icon", "name", "info", "link"],
  components: {
    Link,
  }
}
</script>
