export default {
    data() {
        return {
            position: null,
        }
    },
    methods:{
        orderBy(key) {
            return key;
        },
        currencyCHF(value, from = false) {
            let val = (value/1).toFixed(2)
            value = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");

            return (from ? 'ab ' : '') + 'CHF ' + value
        },
        formatDate(dateString, addDays = 0) {
            let date = new Date(dateString);
            date.setDate(date.getDate() + addDays);
            return new Intl.DateTimeFormat('default', { dateStyle: 'medium' }).format(date);
        },
        formatDateWeather(dateString) {
            let date = new Date(dateString);
            return date.toLocaleDateString('en-GB', {
                day : 'numeric',
                month : 'numeric',
                year : 'numeric'
            }).split('/').join('.');
        },
        percentage(val1, val2) {
            return Math.round(parseInt(val1) / parseInt(val2) * 100);
        },
        truncate(text, length, suffix = '') {
            if (text.length > length) {
                return text.substring(0, length) + suffix;
            } else {
                return text;
            }
        },
        getUserMarker() {
          return {
              position: { lat: parseFloat(this.position.latitude), lng: parseFloat(this.position.longitude) },
              type: "me",
              icon: {
                  scaledSize: { width: 32, height: 50 },
                  url: "/marker/user.svg"
              }
          }
        },
        setMarker: function(data, icon, starting_marker_id = 0, exactId) {
            let markers = []

            if(typeof this.position != 'undefined') {
              markers.push(this.getUserMarker())
            }

            let marker_id = starting_marker_id,
                latitude = 0,
                longitude = 0;
            data.forEach(marker => {
                latitude = parseFloat(marker.geoInfo ? marker.geoInfo.latitude : marker.coordinates.lat);
                longitude = parseFloat(marker.geoInfo ? marker.geoInfo.longitude : marker.coordinates.lng);

                markers.push({
                    id: exactId ? marker.id : marker_id++,
                    header: marker.name,
                    description: marker.address,
                    link: marker.dirLink ?? marker.link,
                    position: { lat: latitude, lng: longitude },
                    type: icon,
                    icon: {
                        scaledSize: { width: 32, height: 50 },
                        url: "/marker/"+icon + ".svg"
                    }
                })
            });
            return markers
        },
        getUserPosition() {

            this.position = localStorage.getItem('position') ? JSON.parse(localStorage.getItem('position')) : undefined;
            if (!navigator.geolocation) {
                console.log('Geolocation is not supported by your browser');
            } else {
                navigator.geolocation.getCurrentPosition(position => {
                    this.position = {
                        latitude: parseFloat(position.coords.latitude),
                        longitude: parseFloat(position.coords.longitude)
                    }
                    var position_string = JSON.stringify(this.position);
                    if(position_string!=localStorage.getItem('position')){
                        localStorage.setItem('position', position_string);
                    }
                }, positionError => {
                    console.log(positionError.message);
                });
            }
        },
        degreesToRadians(degrees) {
            return degrees * Math.PI / 180;
        },
        distanceInKm(lat2, lon2) {
            this.getUserPosition();
            if(typeof this.position != 'undefined'){
                var earthRadiusKm = 6371,
                lat1 = parseFloat(this.position.latitude),
                lon1 = parseFloat(this.position.longitude);
                lat2 = parseFloat(lat2);
                lon2 = parseFloat(lon2);

                var dLat = this.degreesToRadians(lat2-lat1);
                var dLon = this.degreesToRadians(lon2-lon1);

                lat1 = this.degreesToRadians(lat1);
                lat2 = this.degreesToRadians(lat2);

                var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
                    Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2);
                var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

                return Math.round(earthRadiusKm * c, 2) + ' km';
            }else{
                return 'Route planen'
            }
        },

        onScroll() {
            const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
            if (currentScrollPosition < 0) {
                return
            }
            // Stop executing this function if the difference between
            // current scroll position and last scroll position is less than some offset
            if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 60) {
                return
            }
            this.showNavbar = currentScrollPosition < this.lastScrollPosition
            this.lastScrollPosition = currentScrollPosition
        },

        getBuddy() {
          const location = window.location.pathname

          if (location.startsWith("/mountain-guide") || location.startsWith("/corvatsch") || location.startsWith("/diavolezza-lagalb")) {
            const buddyParts = location.split("/")

            return {
              buddy: buddyParts[1],
              isRoot: buddyParts.length > 2 ? false : true
            }
          }

          return false
        }
    }
}
