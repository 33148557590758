<template>

  <div>
    <div class="w-full grid gap-2 lg:gap-4 " >
      <div class="bg-sw-gray-light px-4 py-2 rounded-2xl text-left flex items-center" v-for="(facility,index) in data" :key="index">
        <div class="w-12 h-12 mr-6 ">
          <div class="sw_percentage-circle">
            <div class="sw_percentage-circle-text" :class="facility.status==='open' ? 'text-sw-green' : 'text-sw-red'">
              <svg class="h-5 w-5">
                <use xlink:href="#icon--clock"></use>
              </svg>
            </div>
            <div class="pie text-sw-green" v-if="facility.status==='open'"></div>
            <div class="pie pie-closed text-sw-red" v-else ></div>
          </div>
        </div>
        <div>
          <div class="flex space-x-6">
            <p class="mb-1"><b>{{ facility.name }}</b></p>
          </div>
          <div class="infosnow-time-schedule"
               :id="'infosnow-time-schedule__id-'+facility.id"
               :data-status="facility.status"
               :data-id="facility.id"
               :data-text-notime="$t('labels.facility-no-time')">
               <span v-if = "facility.status==='closed'">{{ $t('labels.closed') }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import $ from "jquery";

export default {
  name: 'Timeschedule',
  props: {
    data: Object,
  },
  data() {
    return {
      facilities: []
    }
  },
  mounted() {
    /*var resorts = [];*/
    let that = this;
    that.lang = 'de';
    that.timeSchedule = $('.infosnow-time-schedule');
    if (that.timeSchedule.length){
      that.loadTimeScheduleInfoSnow();
    }
  },
  methods: {
    loadTimeScheduleInfoSnow(){
      let that = this;
      let today = new Date();
      let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      let time = today.getHours() + ":" + today.getMinutes();
      that.timeSchedule.each(function() {
        let facilityId = $( this ).data('id');
        let status = $(this).data('status');
        if (status==='open'){
          let urlInfoSnowTime = 'https://infosnow.ch/schedule/?lang='+that.lang+'&e='+facilityId+'&date='+date+'&start='+time+'&end=&limit=1&dir=&ref=infosnow-time-schedule__id-'+facilityId;
          $.getScript( urlInfoSnowTime, function() {
            let elmSchedule = $('#infosnow-time-schedule__id-'+facilityId);
            if (elmSchedule.find('.infosnowSchedule').length===0) {
              elmSchedule.html(elmSchedule.data('textNotime'));
            }
          });
        }
      });
    }
  },
}
</script>