<template>

  <div>
    <div class="sw_textpicslider -mx-4 sm:-mx-6 lg:mx-0 text-center" v-if="appearance.layout == 'default'">
      <div class="mx-auto">
        <div class="mb-4 md:mb-8" v-if="content.header">
          <Headers :content="content"/>
        </div>
        <swiper
            class="relative bg-black" v-if="nestedContent" :class="nestedContent.length > 1 ? 'pb-16' : 'pb-6'"
            :slides-per-view="1"
            :space-between="0"
            :pagination="{ clickable: true }"
            :breakpoints="breakpoints"
        >

          <swiper-slide v-for="(child, index) in nestedContent" :key="index" class="h-auto flex flex-col text-white">
            <div class="px-8 sw_gradient mb-4 -mt-px">
              <ImageComponent :image="child['child'].content.media[0]" imgClass="" class="inline-block h-56 md:h-80 w-full"
                              v-if="child['child'].content.media[0]"/>
            </div>
            <div class="px-8 flex-1">
              <Headers :content="child['child'].content" color="white mb-6"/>
              <Bodytext :content="child['child'].content" v-if="child['child'].content.bodytext"/>
            </div>
            <div class="px-8">
              <Link :style="0" class="w-full" linkClass="w-full" :text="child['child'].content.headerLink.title"
                    :target="child['child'].content.headerLink.target" :link="child['child'].content.headerLink.url"
                    v-if="child['child'].content.headerLink.url != ''"/>
            </div>
          </swiper-slide>

        </swiper>
      </div>
    </div>

    <div v-if="appearance.layout == 'layout-1'">
      <div class="mx-auto grid gap-5 lg:grid-cols-3">
        <div class="flex flex-col rounded-lg shadow-sw-1 bg-sw-white overflow-hidden relative" v-for="(child, index) in nestedContent" :key="index">
          <a :href="'tel:' + child['child'].content.subheader.replace(/\s/g, '').toLowerCase()">
            <div class="flex-1 p-6 flex flex-col justify-between">
              <div class="flex-1">
                <div class="block mt-2">
                  <h3 v-if="child['child'].content.header">{{ child['child'].content.header }}</h3>
                  <div v-if="child['child'].content.bodytext" v-html="child['child'].content.bodytext"></div>
                </div>
              </div>
              <div class="mt-4">
                <div class="space-y-2 text-lg">
                  <span class="flex justify-end items-center text-3xl transition ease-in-out duration-150">
                    <svg class="flex-shrink-0 h-7 w-7"><use xlink:href="#icon--phone"></use></svg>
                    <span class="ml-3">{{ child['child'].content.subheader }}</span>
                  </span>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import 'swiper/swiper-bundle.css';

import {Swiper, SwiperSlide} from 'swiper/vue';
import Link from "@/components/contents/partials/Link";
import ImageComponent from "@/components/contents/partials/Image";

import SwiperCore, {
  Pagination
} from 'swiper';
import Headers from "@/components/contents/partials/Headers";
import Bodytext from "@/components/contents/partials/Bodytext";

SwiperCore.use([Pagination]);

export default {
  name: 'TextPicSlider',
  components: {
    Swiper,
    SwiperSlide,
    Headers,
    Bodytext,
    Link,
    ImageComponent
  },
  props: {
    content: Object,
    nestedContent: Object,
    appearance: Object
  },
  data() {
    return {
      breakpoints: {
        640: {
          slidesPerView: this.content.children.length < 2 ? this.content.children.length : 2,
          spaceBetween: 0,
        },
        1200: {
          slidesPerView: this.content.children.length < 3 ? this.content.children.length : 3,
          spaceBetween: 0,
        }
      }
    }
  },
  mounted() {
  }
}
</script>