<template>
  <div class="info-layer info-layer--no-picture" id="modal" :data-uid="infobox.uid" v-show="isBoxShowing && infobox.text">
    <button class="info-layer__close" data-close-info-layer="" @click="closeBox">
      <svg class="icon icon--x w-4 h-4"><use xlink:href="#icon--x"></use></svg>
    </button>
    <div class="info-layer__content">
      <div class="info-layer__content-inner">
        <h3 class="text-uppercase modal_header" v-if="infobox.subheader">{{ infobox.subheader }}</h3>
        <h2 class="text-uppercase modal_header" v-if="infobox.header">{{ infobox.header }}</h2>
        <div class="modal_text" v-if="infobox.text" v-html="infobox.text"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import $cookies from "vue-cookies";


export default {
  name: 'TheInfobox',
  data() {
    return {
      infobox: '',
      isBoxShowing: $cookies.isKey("pwa_infobox") ? false : true
    }
  },
  methods: {
    closeBox () {
      $cookies.set('pwa_infobox', 'clicked');
      this.isBoxShowing = false
    }
  },
  mounted() {
    axios.get('https://www.corvatsch-diavolezza.ch/?type=123&pid=481').then(response => {
      this.infobox = response.data;
    });
  }
}

</script>
