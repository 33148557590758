<template>

  <div class="sm:grid sm:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-8 text-center space-y-8 sm:space-y-0">
    <div class="-mx-4 sm:-mx-0 bg-sw-gray-light px-4 sm:px-0 h-full flex flex-col" v-for="restaurant in data.json" :item="restaurant" :key="restaurant.uid">

      <swiper
          class="relative -mx-4 sm:-mx-0 px-4 sm:px-0 text-sw-gray-light" style="background: linear-gradient(white 50%, currentColor 50%);"
          :slides-per-view="1.1"
          :centered-slides="true"
          :loop="true"
          :space-between="15"
          :breakpoints="breakpoints"
      >
        <swiper-slide v-for="(image, index) in restaurant.images" :key="index">
          <div class="inline-block w-full min-h-56 h-56 lg:aspect-none" >
            <img :src="image" class="w-full h-full object-center object-cover lg:w-full lg:h-full">
          </div>
        </swiper-slide>
      </swiper>
      <div class="py-4 md:py-6 px-4 lg:px-8 flex-1 flex flex-col h-full justify-between">
        <div>
          <a class="rounded-md bg-white px-3 py-1 inline-flex items-center mb-3">
            <svg class="w-3.5 h-3.5 mr-2 text-sw-primary">
              <use xlink:href="#icon--mountain"></use>
            </svg>
            <p class="uppercase mb-0">{{restaurant.mountain.name}}</p>
          </a>
          <h3>{{restaurant.name}}</h3>
          <p v-if="restaurant.status">
            <span class="font-black mr-2" :class="[restaurant.status.status != 'closed' ? 'text-sw-green' : 'text-sw-red']">{{restaurant.status.textStatus}}</span>
            <span class="text-gray-500" v-if="restaurant.status.text">{{restaurant.status.text}}</span>
          </p>
          <div class="flex flex-wrap justify-center space-x-4 mt-6" v-if="restaurant.food_drink_menu != ''">
            <a target="_blank" class="flex items-center font-black" :href="menu.path" v-for="(menu, index) in restaurant.food_drink_menu" :key="index">
              <svg class="icon icon--download w-4 h-4 mr-2"><use xlink:href="#icon--download"></use></svg>
              {{ menu.title }}
            </a>
          </div>
        </div>
        <div class="flex items-center justify-center space-x-2 mt-2">
          <a :href="'tel:' + restaurant.phone.replace(/\s/g, '').toLowerCase()" class="mt-2 w-9 h-9 flex items-center justify-center rounded-full border border-black">
            <svg class="icon icon--phone w-5 h-5"><use xlink:href="#icon--phone"></use></svg>
          </a>
          <div v-if = "restaurant.booking_link !==''">
            <Link :style="0" :text="$t('labels.book-table')" :link="restaurant.booking_link" target="_blank" />
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import { Swiper, SwiperSlide } from 'swiper/vue';
import Link from "@/components/contents/partials/Link";

export default {
  name: 'Restaurants',
  props: {
    data: Object,
  },
  components: {
    Swiper,
    SwiperSlide,
    Link
  },
  data() {
    return {
      breakpoints: {
        640: {
          slidesPerView: 1.25,
        },
      }
    }
  },
}
</script>
