<template>

  <div v-if="content.header">
    <h1 :class="[{ 'mt-2': content.subheader != ''}, content.headerPosition != '' ? 'text-' + content.headerPosition : '']" class="" v-if="header_layout == 1">{{ content.header }}</h1>
    <h2 :class="[{ 'mt-2': content.subheader != ''}, content.headerPosition != '' ? 'text-' + content.headerPosition : '']" class="" v-if="header_layout == 2 || header_layout == 0">{{ content.header }}</h2>
    <h3 :class="[{ 'mt-2': content.subheader != ''}, content.headerPosition != '' ? 'text-' + content.headerPosition : '']" class="" v-if="header_layout == 3">{{ content.header }}</h3>
    <h4 :class="[{ 'mt-2': content.subheader != ''}, content.headerPosition != '' ? 'text-' + content.headerPosition : '']" class="" v-if="header_layout == 4">{{ content.header }}</h4>
    <h5 :class="[{ 'mt-2': content.subheader != ''}, content.headerPosition != '' ? 'text-' + content.headerPosition : '']" class="" v-if="header_layout == 5">{{ content.header }}</h5>
  </div>

</template>

<script>
export default {
  props: {
    content: Object,
  },
  data() {
    return {
      header_layout: this.content.headerLayout ?? this.content.header_layout,
    }
  },
  name: 'Header',
}
</script>