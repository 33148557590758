<template>
  <div>
    <a v-if="style == 0 || style == 1 || style == 2 || style == 3"
       :href="link" :target="target"
       class="text-xs md:text-sm md:leading-5 w-48 max-w-full font-sans uppercase text-center tracking-widest inline-flex items-center justify-center px-4 py-2.5 border border-transparent font-black rounded-full transition-all duration-200 ease-in-out mt-2"
      :class="[{'bg-sw-secondary hover:bg-sw-secondary-light text-black': style == 0},
      {'bg-white text-current': style == 1},
      {'bg-sw-primary text-white': style == 2},
      {'border border-black': style == 3},
      linkClass]">
      {{ text }}
    </a>

    <a v-if="style == 10 || style == 11"
       :href="link" :target="target"
       class="text-xs md:text-sm md:leading-48max-w-lfullfont-sans uppercase tracking-widest inline-flex items-center justify-center font-black transition-all duration-200 ease-in-out"
      :class="[{'text-sw-primary hover:text-gray-100 hover:underline': style == 10},
      {'text-white hover:text-gray-100 hover:underline': style == 11},
      linkClass]">
      {{ text }} →
    </a>

    <a v-if="style == 20"
       :href="link" class="text-xs md:text-sm md:leading-5 w-48 max-w-full font-sans uppercase tracking-widest flex items-center justify-center h-8 w-8 bg-gray-500 text-white rounded-sm absolute top-2 right-2"
       :target="target"
       :class="linkClass">
      <svg class="h-5 w-5 text-white">
        <use :xlink:href="'#icon--x'"></use>
      </svg>
    </a>

    <a v-if="style == 21" :href="link" :target="target"
       class="text-xs md:text-sm md:leading-5 w-48 max-w-full font-sans uppercase tracking-widest inline-flex items-center justify-center px-3 py-2.5 border border-transparent font-black rounded-full transition-all duration-200 ease-in-out w-full mt-2 bg-white text-current">
      {{ text }}
    </a>

    <a v-if="style == 200"
       :href="link" class="text-xs md:text-sm md:leading-5 w-48 max-w-full font-sans uppercase tracking-widest inline-flex items-center justify-center px-3 py-2.5 border border-transparent font-extrabold rounded-full transition-all duration-200 ease-in-out bg-sw-primary text-white"
       :target="target"
       :class="linkClass">

      <svg class="h-5 w-5 text-white mr-2">
        <use :xlink:href="'#icon--directions'"></use>
      </svg>

      {{ text }}
    </a>

  </div>
</template>

<script>
export default {
  name: 'Link',
  props: {
    link: String,
    target: String,
    text: String,
    linkClass: String,
    style: Number,
  },
}
</script>
