<template>
  <div v-if="content.header != '' || content.subheader != ''">
    <SubHeader v-if="content.subheader != ''" :content="content" />
    <Header v-if="content.header != ''" :content="content" />
  </div>
</template>

<script>

import Header from "@/components/contents/partials/Header";
import SubHeader from "@/components/contents/partials/SubHeader";

export default {
  props: {
    content: Object,
  },
  name: 'Headers',
  components: {
    Header,
    SubHeader,
  }
}
</script>