<template>
<div>

  <div v-if="appearance.layout == 'default'">
    <div class="">
      <Headers :content="content" />
      <div class="mt-6 grid grid-cols-3 gap-3">
        <a :href="p.link" class="group relative shadow-lg" v-for="p in content.menu" :key="p">
          <div class="w-full min-h-80 bg-black aspect-w-1 aspect-h-1 overflow-hidden group-hover:opacity-75 lg:h-80 lg:aspect-none transition-all duration-200 ease-in-out">
            <img class="w-full h-full object-center object-cover lg:w-full lg:h-full" alt="" :src="p.media[0].publicUrl" v-if="p.media[0]">
          </div>
          <div class="py-2 px-2 lg:py-4 font-headline flex justify-center items-center">
            <svg class="w-3.5 h-3.5 mr-1 text-sw-primary">
              <use xlink:href="#icon--mountain"></use>
            </svg>
            {{ p.title }}
          </div>
        </a>
      </div>
    </div>
  </div>

</div>
</template>

<script>

import Headers from "@/components/contents/partials/Headers";

export default {
  name: 'MenuPages',
  components: {
    Headers,
  },
  props: {
    content: Object,
    appearance: Object,
    page: Object,
  },
}
</script>
