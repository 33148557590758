<template>

  <div v-if="data.facilities">

    <div class="toggle-bg-color" v-for="(facility, index) in data.facilities.list" :key="index">

      <div class="accordion-content px-4 py-4 md:py-8" >
        <div class="grid grid-cols-12 gap-1 md:gap-4">
          <div class="col-auto md:col-span-1 flex items-center justify-center" v-if="facility.TypID">
            <div :class="facility.StatusID == 1 ? 'text-sw-green' : 'text-sw-red'">
              <svg class="w-6 h-6 md:mx-auto"><use :xlink:href="'#' + $t('labels.type-icon-' + facility.TypID)"></use></svg>
            </div>
          </div>
          <div class="col-span-6 md:col-span-3" v-if="facility.TypID">
            <p class="mb-0 pl-2 md:pl-0">
              {{facility.Name}}<br>
              <span class="text-sm text-gray-400">{{ $t('labels.facility-type-' + facility.TypID) }}</span>
            </p>
          </div>
          <div class="col-span-4 md:col-span-2 font-black" :class="facility.StatusID == 1 ? 'text-sw-green' : 'text-sw-red'" v-if="facility.StatusID">
            <p class="text-right md:text-left todo-color">{{ $t('labels.facility-status-' + facility.StatusID) }}</p>
          </div>

          <div class="col-span-12 md:col-span-6 lg:col-span-3 xxl:col-span-2">
            <br class="md:hidden">
            <div class="flex" v-if="facility.timetable">
              <div class="mr-4">
                <svg class="icon icon--calendar w-5 h-5"><use xlink:href="#icon--calendar"></use></svg>
              </div>
              <div class="icon-wrap__text">
                <p class="mb-1" v-if="facility.timetable.days.length == 7">
                  {{ $t('labels.daily') }}
                </p>
                <p class="mb-1" v-else v-for="(day, index) in facility.timetable.days" :key="index">
                  {{ $t('labels.day-' + day) }}<span v-if="index != facility.timetable.days.length - 1">, </span>
                </p>
                <span class="text-sm text-gray-400">
                  <span v-if="facility.timetable.time">{{ truncate(facility.timetable.time, 30, '...') }}</span><br>
                  <span v-if="facility.timetable.remarks.de">{{ facility.timetable.remarks.de }}</span>
                </span>
              </div>
            </div>
          </div>

          <div class="col-span-12 md:col-span-6 md:col-start-6 lg:col-span-3 lg:col-start-auto xxl:col-span-4">
            <br class="lg:hidden">
            <div class="flex items-center">
              <div class="mr-4">
                <svg class="icon icon--clock w-5 h-5"><use xlink:href="#icon--clock"></use></svg>
              </div>
              <div>
                <div class="flex items-center">
                  <div class="infosnow-time-schedule"
                       :id="'infosnow-time-schedule__id-' + facility.Id"
                       :data-id="facility.Id"
                       :data-text-notime="$t('labels.facility-no-time')"></div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import Mixins from "@/Mixins";
import $ from "jquery";

export default {
  name: 'Lifts',
  mixins: [Mixins],
  props: {
    data: Object,
  },
  mounted () {
    let that = this;
    that.timeSchedule = $('.infosnow-time-schedule');
    if (that.timeSchedule.length){
      that.loadTimeScheduleInfoSnow();
    }
  },
  methods: {
    loadTimeScheduleInfoSnow(){
      let that = this;
      that.timeSchedule.each(function() {
        let facilityId = $( this ).data('id');
        let today = new Date();
        let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
        let time = today.getHours() + ":" + today.getMinutes();
        let urlInfoSnowTime = 'https://infosnow.ch/schedule/?lang='+that.lang+'&e='+facilityId+'&date='+date+'&start='+time+'&end=&limit=1&dir=&ref=infosnow-time-schedule__id-'+facilityId;
        $.getScript( urlInfoSnowTime, function() {
          let elmSchedule = $('#infosnow-time-schedule__id-'+facilityId);
          if (elmSchedule.find('.infosnowSchedule').length===0) {
            elmSchedule.html(elmSchedule.data('textNotime'));
          }
        });
      });
    }
  },
}
</script>