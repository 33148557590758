<template>

  <div class="h-full w-full">

    <div :class="['text-' + content.position.horizontal, 'md:grid-cols-' + content.count.columns]" class="grid gap-4 h-full w-full"
         v-if="content.slider != 1">
      <template v-for="row in content.rows">
        <template v-for="columns in row">
          <div v-for="image in columns" :key="image" class="h-full w-full">
            <figure class="inline-block h-full w-full">
              <img :class="[imgClass, {'border-1 border-gray-200': content.border.enabled == true}]" class="max-w-full"
                   :src="image.publicUrl" :title="image.properties.title" :alt="image.properties.alternative"
                   :width="image.properties.dimensions.width" :height="image.properties.dimensions.height">
              <figcaption v-if="image.properties.description != null" class="mt-2 text-left">
                {{ image.properties.description }}
              </figcaption>
            </figure>
          </div>
        </template>
      </template>
    </div>
    <div v-else class="overflow-hidden h-full w-full">

      <swiper
          class="relative h-full w-full"
          navigation
          :slides-per-view="content.count.columns"
          :space-between="content.count.columns > 1 ? 15 : 0"
          :pagination="{ clickable: true }"
      >

        <template v-for="row in content.rows">
          <template v-for="columns in row">
            <swiper-slide v-for="(image, index) in columns" :key="index" class="h-full w-full">
              <figure class="inline-block w-full h-full">
                <img :class="[imgClass, {'border-1 border-gray-200': content.border.enabled == true}]" class="h-full w-full object-cover max-w-full"
                     :src="image.publicUrl" :title="image.properties.title" :alt="image.properties.alternative"
                     :width="image.properties.dimensions.width" :height="image.properties.dimensions.height">
                <figcaption v-if="image.properties.description != null" class="mt-2 text-left">
                  {{ image.properties.description }}
                </figcaption>
              </figure>
            </swiper-slide>
          </template>
        </template>

      </swiper>

    </div>
  </div>

</template>

<script>

import 'swiper/swiper-bundle.css';

import { Swiper, SwiperSlide } from 'swiper/vue';

import SwiperCore, {
  Navigation,
  Pagination
} from 'swiper';

SwiperCore.use([Navigation, Pagination]);

export default {
  name: 'Media',
  props: {
    content: Object,
    imgClass: String,
  },
  components: {
    Swiper,
    SwiperSlide,
  },
}
</script>
