<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <Disclosure as="nav" class="bg-white w-full shadow top-0 z-50 navbar_top sticky" v-slot="{ open }">

    <div ref="nav" class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-16">
      <div class="flex justify-between h-16 lg:h-auto py-2 items-center">

        <div class="flex">
          <a :href="homepage" title="Corvatsch Diavolezza Lagalb"><img class="h-12 w-auto mr-4" src="https://www.corvatsch-diavolezza.ch/fileadmin/user_upload/corvatsch_diavolezza_lagalb/logos/logo_corvatsch.svg" alt="Corvatsch Diavolezza Lagalb Logo"></a>
        </div>

        <a v-if="buddy && !buddy.isRoot" :href="`/${buddy.buddy}`">
          <svg class="h-6 w-6 text-white">
            <use xlink:href="#icon--chevron-left"></use>
          </svg>
        </a>

        <div class="-mr-2 flex items-center">
          <DisclosureButton @click="addClass" class="inline-flex items-right justify-center p-2 rounded-md focus:outline-none text-black">
            <span class="sr-only">Open main menu</span>
            <div v-if="!open" class="w-6">
              <svg class="inline-block h-4 w-4"><use xlink:href="#icon--menu"></use></svg>
            </div>
            <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
          </DisclosureButton>
        </div>
      </div>
    </div>

    <DisclosurePanel :style="{ height: navHeight + 'px' }" :class="open ? 'overflow-y-scroll bg-white' : ''" class="">

      <div class="pt-4 pb-36 space-y-2">

        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-16">
          <a :href="buddy_navigation.link" :target="buddy_navigation.target" class="flex items-center block" v-if="buddy_navigation!=null && buddy_navigation.nav_hide != 1">
            <div class="h-8 w-8 mr-4 flex items-center justify-center">
              <svg class="h-6 w-5 text-sw-primary">
                <use xlink:href="#icon--mountain"></use>
              </svg>
            </div>
            <span class="py-1 text-lg font-black">{{buddy_navigation.title}}</span>
          </a>
        </div>

        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-16">
          <p class="font-headline font-bold text-lg text-gray-300">{{ $t('labels.everything-at-a-glace') }}</p>
        </div>

        <!--    TODO: active state!    -->
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-16" v-if="buddy_navigation!=null">
          <ul role="list">
            <li v-for="page in buddy_navigation['children']" class="cursor-pointer" :key="page">
              <div class="flex pb-3" :class="[page.current == 1 ? 'text-gray-300' : '']" v-if="page.nav_hide != 1" @click="page.children && page.children.length > 0 ? openCloseMenu(page) : directLink(page.link)">
                <div class="h-8 w-8 mr-4 flex items-center">
                  <svg class="h-6 w-8" :class="'text-' + page.color">
                    <use :xlink:href="'#icon--' + page.icon"></use>
                  </svg>
                </div>
                <span class="py-1 text-lg" v-html="page.title"></span>
              </div>
            </li>
          </ul>
        </div>

        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-16" v-else>
          <ul role="list">
            <li v-for="page in main_navigation['children'][3]['children']" :key="page">
              <a :href="page.link" :target="page.target" class="flex pb-3" v-if="page.nav_hide != 1" :class="[buddy_navigation != null && buddy_navigation.uid == page.uid ? 'sw-grayscale' : '', page.current == 1 ? 'text-gray-300' : '']">
                <div class="h-8 w-8 mr-4 flex items-center">
                  <svg class="h-6 w-8" :class="'text-' + page.color">
                    <use :xlink:href="'#icon--' + page.icon"></use>
                  </svg>
                </div>
                <span class="py-1 text-lg" v-html="page.title"></span>
              </a>
            </li>
          </ul>
        </div>

        <div class="max-w-7xl mx-auto sm:px-6 lg:px-16 pb-2">
          <hr class="text-sw-gray-light">
        </div>

        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-16" v-if="buddy_navigation==null">
          <p class="font-headline font-bold text-lg text-gray-300">{{ $t('labels.where-are-you-on-the-way') }}</p>
        </div>
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-16" v-else>
          <p class="font-headline font-bold text-lg text-gray-300">{{ $t('labels.more-mountains') }}</p>
        </div>

        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-16">
          <ul role="list">
            <template v-for="page in sub_navigation">
              <li :key="page" v-if="page.current != 1 && page.active != 1 && page.nav_hide != 1 && buddy_navigation==null && page.title != 'Mountain Guide'">
                <a :href="page.link" :target="page.target" class="flex items-center block pb-3">
                  <div class="h-8 w-8 mr-4 flex items-center justify-center">
                    <svg class="h-6 w-5 text-sw-primary">
                      <use xlink:href="#icon--mountain"></use>
                    </svg>
                  </div>
                  <span class="py-1 text-lg font-black" v-html="page.nav_title" v-if="page.nav_title"></span>
                  <span class="py-1 text-lg font-black" v-html="page.title" v-else></span>
                </a>
              </li>
            </template>
            <template v-for="page in sub_navigation">
              <li :key="page" v-if="page.current != 1 && page.active != 1 && page.nav_hide != 1 && buddy_navigation!=null && buddy_navigation.title != page.nav_title && buddy_navigation.title != page.title">
                <a :href="page.link" :target="page.target" class="flex items-center block pb-3">
                  <div class="h-8 w-8 mr-4 flex items-center justify-center">
                    <svg class="h-6 w-5 text-sw-primary">
                      <use xlink:href="#icon--mountain"></use>
                    </svg>
                  </div>
                  <span class="py-1 text-lg font-black" v-html="page.nav_title" v-if="page.nav_title"></span>
                  <span class="py-1 text-lg font-black" v-html="page.title" v-else></span>
                </a>
              </li>
            </template>
          </ul>
        </div>

        <div class="max-w-7xl mx-auto sm:px-6 lg:px-16 pb-2">
          <hr class="text-sw-gray-light">
        </div>

        <div>
          <div class="px-6 mt-6 text-center">
            <div class="relative">
              <template v-for="language in languages">
                <button @click="showDropdown = !showDropdown" type="button" :class="[showDropdown ? 'rounded-t-md' : 'rounded-md']" class="w-40 bg-white relative border border-gray-300 shadow-sm pl-3 pr-10 py-2 text-left cursor-pointer focus:outline-none focus:ring-0" aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label"  :key="language.languageId" v-if="language.active == 1">
                  <span class="flex items-center truncate">
                    <svg class="h-5 w-5 mr-2">
                      <use xlink:href="#icon&#45;&#45;globe"></use>
                    </svg>
                    {{ language.title }}
                  </span>
                  <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <svg class="h-5 w-5" v-if="showDropdown">
                      <use xlink:href="#icon&#45;&#45;chevron-up"></use>
                    </svg>
                    <svg class="h-5 w-5" v-else>
                      <use xlink:href="#icon&#45;&#45;chevron-down"></use>
                    </svg>
                  </span>
                </button>
              </template>
              <ul v-if="showDropdown" style="margin-top:-1px;" class="w-40 mx-auto bg-white max-h-60 rounded-b-md py-1 border border-gray-300 overflow-auto focus:outline-none divide-y divide-gray-200" tabindex="-1" role="listbox" aria-labelledby="listbox-label">
                <template v-for="language in languages">
                  <li class="cursor-pointer select-none relative pl-3 pr-10 py-2 text-sm text-gray-500 hover:text-black" id="listbox-option-0" role="option" :key="language.languageId" v-if="language.active == 0">
                    <a :href="language.link" class="flex items-center truncate">
                      <svg class="h-5 w-5 mr-2">
                        <use xlink:href="#icon&#45;&#45;globe"></use>
                      </svg>
                      {{ language.title }}
                    </a>
                  </li>
                </template>
              </ul>
            </div>
          </div>
        </div>

        <div>
          <div class="px-6 mt-10 text-center">
            <p class="mb-0 text-gray-500"><span v-html="$t('labels.footer-link')"></span></p>
          </div>
        </div>
      </div>

    </DisclosurePanel>
  </Disclosure>

</template>

<script>
import axios from "axios";
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { XIcon } from '@heroicons/vue/outline'
import Mixins from "@/Mixins";

export default {
  mixins: [Mixins],
  props: {
    homepage: Object,
    languages: Object
  },
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    XIcon,
  },
  data() {
    return {
      main: [],
      main_navigation: [],
      sub_navigation: [],
      buddy_navigation: null,
      isAddClass: false,
      navHeight: '100vh',
      menuOpen: window.innerWidth >= 640 ? 'all' : 0,
      buddy: null,
      showDropdown: false
    }
  },
  methods: {
    directLink: function(link) {
      window.location.replace(link)
    },
    addClass: function() {
      this.isAddClass = !this.isAddClass;
      this.isAddClass ? document.body.classList.add("overflow-hidden") : document.body.classList.remove("overflow-hidden");
    },
    setNavHeight() {
      var navHeight = this.$refs.nav.clientHeight;
      this.navHeight = window.innerHeight - navHeight;
    },
    openCloseMenu(page) {
      this.menuOpen = window.innerWidth >= 640 ? 'all' : (this.menuOpen==page.uid ? 0 : page.uid);
    },
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.onScroll)
  },
  mounted() {
    this.buddy = Mixins.methods.getBuddy()

    var first = window.location.pathname.split('/')[1],
        second = window.location.pathname.split('/')[2],
        mountain,
        buddys = ['mountain-guide', 'corvatsch', 'diavolezza-lagalb'],
        lang = ['en', 'it'];
    this.setNavHeight();
    window.addEventListener('scroll', this.onScroll)

    addEventListener('resize', () => {
      this.menuOpen = window.innerWidth >= 640 ? 'all' : 0
    });

    if (buddys.includes(first)) {
      mountain = '/' + first;
    } else if (buddys.includes(second)) {
      mountain = '/' + first + '/' + second;
    } else if (lang.includes(first)) {
      mountain = '/' + first;
    } else {
      mountain = '/';
    }

    this.main = {
      'baseUrl': mountain
    }

    axios.get('https://api.corvatsch-diavolezza.ch' + this.main['baseUrl'] + '?type=834').then(response => {
      var navigation = response.data['navigation'],
          pos = navigation.length-1;
      this.sub_navigation = navigation[0]['children'];
      this.main_navigation = response.data['navigation'][0];
      if(pos != 0){
        this.buddy_navigation = response.data['navigation'][navigation.length-1];
      }
    })
  },
}
</script>