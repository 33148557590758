<template>
  <div class="relative overflow-hidden space-y-2 md:space-y-6 sw_pages-teaser">

    <Headers :content="content"/>
    <div class="grid grid-cols-1 md:grid-cols-2 md:gap-x-4 lg:gap-x-8 mt-6 sw_pages-teaser-wrapper" v-if="content.menu">
      <a class="h-56 lg:h-auto relative flex sw_pages-teaser-box" :href="page.link" v-for="page in content.menu"
         :key="page">
        <!--
        <img v-if="page.media" alt="" class="absolute inset-0 w-full h-full object-center object-cover rounded-md">
        -->
        <div
            class="relative w-full flex flex-col items-center justify-center text-center p-4 sm:p-8 lg:py-20 rounded-md bg-cover bg-center bg-blend-multiply"
            :style="{'background-image': 'url(' + page.media[0]['publicUrl'] + ')'}" :class="'bg-' + page.color"
            v-if="page.media">
          <div class="text-white">
            <b>{page.title}</b><br />
            {page.description}<br />
          </div>
          <!--            <h2 class="text-white block text-xl leading-8 font-bold tracking-tight sm:text-2xl">{{ page.subtitle }}</h2>
                      <p v-if="page.abstract" class="mt-2.5 font-medium text-white">{{ page.abstract }}</p>-->
        </div>
      </a>
    </div>
  </div>
</template>

<script>

import Headers from "@/components/contents/partials/Headers";

export default {
  name: 'MenuSubpages',
  components: {
    Headers,
  },
  props: {
    content: Object,
    appearance: Object,
    page: Object,
  },
}
</script>