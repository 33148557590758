import { createI18n } from 'vue-i18n';
import de from './locales/de'
import en from './locales/en'
import it from './locales/it'

const i18n = createI18n({
    legacy: false,
    locale: 'de',
    fallbackLocale: 'de',
    globalInjection: true,
    messages: {
        de,
        en,
        it,
    }
});

export default i18n;