<template>

  <div v-if="data.parks.list">

    <div class="toggle-bg-color" v-for="(park, index) in data.parks.list" :key="index">

      <div class="accordion-content px-4 py-4 md:py-8" >
        <div class="grid grid-cols-12 gap-1 md:gap-4">
          <div class="col-auto md:col-span-1 flex items-center justify-center" v-if="park.TypID">
            <div :class="park.StatusID == 8 ? 'text-sw-green' : 'text-sw-red'">
              <svg class="w-6 h-6 md:mx-auto"><use :xlink:href="'#' + $t('labels.type-icon-' + park.TypID)"></use></svg>
            </div>
          </div>
          <div class="col-span-6 md:col-span-3">
            <p class="mb-0 pl-2 md:pl-0" v-if="park.Name">
              {{park.Name}}<br>
              <span class="text-sm text-gray-400">{{ $t('labels.park-type-' + park.TypID) }}</span>
            </p>
          </div>
          <div class="col-span-4 md:col-span-2 font-black" :class="park.StatusID == 8 ? 'text-sw-green' : 'text-sw-red'" v-if="park.StatusID">
            <p class="text-right md:text-left todo-color">{{ $t('labels.park-status-' + park.StatusID) }}</p>
          </div>

          <div class="col-span-12 md:col-span-6 lg:col-span-3 xxl:col-span-2">
            <br class="md:hidden">
            <div class="flex" v-if="park.timetable">
              <div class="mr-4">
                <svg class="icon icon--calendar w-5 h-5"><use xlink:href="#icon--calendar"></use></svg>
              </div>
              <div class="icon-wrap__text">
               <p class="mb-1" v-if="park.timetable.days.length == 7">
                 {{ $t('labels.daily') }}
                </p>
                <p class="mb-1" v-else-if="park.timetable.days != null" v-for="(day, index) in park.timetable.days" :key="index">
                  {{ $t('labels.day-' + day) }}<span v-if="index != park.timetable.days.length - 1">, </span>
                </p>
              </div>
            </div>
          </div>

          <div class="col-span-12 md:col-span-6 md:col-start-6 lg:col-span-3 lg:col-start-auto xxl:col-span-4">
            <br class="lg:hidden">
            <div class="flex items-center" v-if="park.timetable">
              <div class="mr-4">
                <svg class="icon icon--clock w-5 h-5"><use xlink:href="#icon--clock"></use></svg>
              </div>
              <div>
                <div class="flex items-center">
                  <span v-if="park.timetable.time">{{ truncate(park.timetable.time, 30, '...') }}</span><br>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import Mixins from "@/Mixins";

export default {
  name: 'Lifts',
  mixins: [Mixins],
  props: {
    data: Object,
  },
}
</script>