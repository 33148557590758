<template>
  <div>

    <img class="m-auto" :src="data.preview" />

    <div class="mb-12 mt-5">
      <p class="text-center ">
        <span v-html="$t('labels.avalanche-bulletin-link')"></span>
      </p>
    </div>

    <div>
      <h2 class="mb-9">{{ $t('labels.current-avalanche-danger') }}</h2>
    </div>

    <div v-for="(link, index) in data.links" :key="index">
      <div v-if="index !== 0">
        <div class="d-block py-2 md:py-2"></div>
        <div class="rounded-lg bg-sw-undefined">
          <hr class="border-sw-gray-light">
        </div>
        <div class="d-block py-2 md:py-2"></div>
      </div>
      <div class="relative flex space-x-4 md:space-x-6">
        <div class="flex-shrink-0">
          <div class="w-12 h-12 md:h-24 md:w-24">
            <a :href="link.uri" target="_blank" >
            <img class="h-full w-full object-cover" src="/images/avalanche_icon.svg" width="48" height="48" />
            </a>
          </div>
        </div>
        <div class="flex-1 min-w-0">
          <div>
            <h3 class="">{{ $t('labels.warning-region') }} {{ link.label }}</h3>
          </div>
          <div>
            <p class="text-gray-500">{{ $t('labels.danger-map-pdf') }}</p>
          </div>
          <div>
            <a :href="link.uri" target="_blank" class="text-xs md:text-sm md:leading-48max-w-lfullfont-sans uppercase tracking-widest inline-flex items-center justify-center font-black transition-all duration-200 ease-in-out text-sw-primary hover:text-gray-500 hover:underline">
              {{ $t('labels.more') }} →
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Avalanche',
  props: {
    data: Object,
  },
}
</script>
