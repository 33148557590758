<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="bg-black w-full shadow-top fixed left-0 right-0 bottom-0 z-50 drop-shadow-sw-1 navbar_bottom">
    <div class="flex justify-center items-center">
      <template v-for="page in navigation" >
        <div v-if="page.season == season || page.season == 1" :key="page" class="flex flex-1 flex-col" >
          <a v-if="!page.children" :href="page.link" class="pt-2 pb-4 px-2 text-white hover:text-opacity-80 transition-all duration-200 ease-in-out md:flex-row items-center justify-center font-light" >
          <span class="flex flex-col items-center justify-start text-center">
            <svg class="h-6 w-8 mb-1"><use v-bind="{'xlink:href': '#icon--' + page.icon}"></use></svg>
            <span class="text-xxs md:text-sm">{{ page.title }}</span>
          </span>
          </a>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    navigation: Object,
    season : String
  },
  data() {
    return {
      selectedSubpage: null,
    }
  },
  methods: {
    openSubmenu(id){
      if(this.selectedSubpage === id){
        this.selectedSubpage = null;
      }else{
        this.selectedSubpage = id;
      }
    }
  },
}
</script>
