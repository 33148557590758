<template>
  <div>
    <div class="d-block py-2 md:py-2" v-if="space === 'xs'"></div>
    <div class="d-block py-3 md:py-4" v-if="space === 'sm'"></div>
    <div class="d-block py-4 md:py-6" v-if="space === 'md'"></div>
    <div class="d-block py-5 md:py-8" v-if="space === 'lg'"></div>
    <div class="d-block py-5 md:py-8" v-if="space === 'xl'"></div>
    <div class="d-block py-5 md:py-8" v-if="space === 'xxl'"></div>
  </div>
</template>

<script>

export default {
  name: 'Space',
  props: {
    space: String,
  },
}
</script>