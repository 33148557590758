<template>
  <div v-if="showCookieBanner" class="fixed bottom-0 inset-x-0 pb-2 sm:pb-5 z-60">
    <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
      <div class="cookie-box p-3 rounded-lg bg-sw-black shadow-lg sm:p-3 border border-white">
        <div class="md:flex items-center justify-between flex-wrap w-full">
          <p class="md:flex-1 text-white my-0">
            <strong class="font-extrabold mb-2 inline-block">{{ $t('cookie.title') }}</strong><br>
            <span v-html="$t('cookie.message')"></span>
          </p>
          <div class="flex pt-4">
            <button @click="reject()" type="button" class="flex-1 uppercase inline-flex items-center justify-center px-3 py-2 border border-white border-transparent font-bold rounded-full transition duration-200 ease-in-out bg-sw-black text-white tracking-wider">
              {{ $t('cookie.decline') }}
            </button>
            <button @click="accept()" type="button" class="flex-1 ml-4 inline-flex items-center justify-center px-3 py-2 border border-transparent font-bold rounded-full transition duration-200 ease-in-out bg-sw-cookie text-white uppercase tracking-wider">
              <svg class="icon icon--cookie w-5 h-5 mr-3 text-white  "><use xlink:href="#icon--cookie"></use></svg>
              {{ $t('cookie.accept') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TheCookieBanner',
  data () {
    return {
      showCookieBanner: false
    }
  },
  methods: {
    accept: function() {
      localStorage.setItem("gtm_accepted", true)

      if (!this.$gtm.enabled()) {
        this.$gtm.enable(true)
      }

      this.showCookieBanner = false
    },
    reject: function() {
      localStorage.setItem("gtm_accepted", false)

      if (this.$gtm.enabled()) {
        this.$gtm.enabled(false)
      }

      this.showCookieBanner = false
    }
  },
  mounted() {
    if (localStorage.getItem("gtm_accepted") === null) {
      this.showCookieBanner = true
    }
  }
}
</script>
