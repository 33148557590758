<template>
  <div>
    <div class="space-y-6 space-y-12">

      <!--    TODAY    -->
      <WeatherItem
          dayOdd="1"
          :title="data.json.info.texts.today.header"
          :text="data.json.info.texts.today.text"
          :day1Date="data.json.station.today.date.iso8601"
          day1DateDiff=""
          :day1Icon="data.json.info.icons.today.morning.id"
          :day1Min="data.json.station.today.morning.temperature.min"
          :day1Max="data.json.station.today.morning.temperature.max"
          :day2Date="data.json.station.today.date.iso8601"
          day2DateDiff=""
          :day2Icon="data.json.info.icons.today.afternoon.id"
          :day2Min="data.json.station.today.afternoon.temperature.min"
          :day2Max="data.json.station.today.afternoon.temperature.max"
      />

      <!--    TOMORROW    -->
      <WeatherItem
          dayOdd="0"
          :title="data.json.info.texts.tomorrow.header"
          :text="data.json.info.texts.tomorrow.text"
          :day1Date="data.json.station.tomorrow.date.iso8601"
          day1DateDiff="1"
          :day1Icon="data.json.info.icons.tomorrow.morning.id"
          :day1Min="data.json.station.tomorrow.morning.temperature.min"
          :day1Max="data.json.station.tomorrow.morning.temperature.max"
          :day2Date="data.json.station.tomorrow.date.iso8601"
          day2DateDiff="1"
          :day2Icon="data.json.info.icons.tomorrow.afternoon.id"
          :day2Min="data.json.station.tomorrow.afternoon.temperature.min"
          :day2Max="data.json.station.tomorrow.afternoon.temperature.max"
      />

      <!--    FORECAST    -->
      <WeatherItem
          dayOdd="1"
          :title="data.json.info.texts.forecast.header"
          :text="data.json.info.texts.forecast.text"
          :day1Date="data.json.station.days['0'].date.iso8601"
          day1DateDiff="2"
          :day1Icon="data.json.info.icons.forecast.day1.id"
          :day1Min="data.json.station.days['0'].temperature.min"
          :day1Max="data.json.station.days['0'].temperature.max"
          :day2Date="data.json.station.days['1'].date.iso8601"
          day2DateDiff="3"
          :day2Icon="data.json.info.icons.forecast.day2.id"
          :day2Min="data.json.station.days['1'].temperature.min"
          :day2Max="data.json.station.days['1'].temperature.max"
      />

      <!--    WEEKTREND    -->
      <WeatherItem
          dayOdd="1"
          :title="data.json.info.texts.weektrend.header"
          :text="data.json.info.texts.weektrend.text"
          :day1Date="data.json.station.days['2'].date.iso8601"
          day1DateDiff="4"
          :day1Icon="data.json.info.icons.forecast.day3.id"
          :day1Min="data.json.station.days['2'].temperature.min"
          :day1Max="data.json.station.days['2'].temperature.max"
          :day2Date="data.json.station.days['3'].date.iso8601"
          day2DateDiff="5"
          :day2Icon="data.json.info.icons.forecast.day4.id"
          :day2Min="data.json.station.days['3'].temperature.min"
          :day2Max="data.json.station.days['3'].temperature.max"
      />



    </div>
    <div class="weather-copyright">
      <br><br>
      <div v-html="data.json.info.legal.html"></div>
    </div>
  </div>
</template>

<script>

import WeatherItem from "@/components/integrations/partials/Weather/Weather";

export default {
  name: 'Weather',
  props: {
    data: Object,
  },
  components: {
    WeatherItem,
  },
}
</script>
