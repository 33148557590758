<template>
  <div :class="{ 'navbar--hidden': !showNavbar }">

    <Svg/>
    <TheNavigation :languages="languages" :homepage="homepage"/>
    <TheServiceNavigation :navigation="page.dock" :season="season"/>

    <div v-if="this.introClosed == false">
      <Intro :navigation="navigation['spashscreen'].colPos0" :season="season"/>
    </div>

    <div class="contents">

      <!--   TODO: if Route is defined (detail etc.), hide component "Contents"!   -->
      <router-view></router-view>

      <Contents @hideFooter="hideFooter" disableFooter :page="page" :contents="contents" :season="season" :languages="languages" v-if="$route.name != 'eventDetail'"/>
<!--      <NotFound v-if="pageNotFound = true"/>-->

    </div>

    <TheFooter v-if="showFooter" :navigation="navigation" :contacts="contacts"/>
    <TheCookieBanner />

    <a :href="page.sos['url']" class="fixed right-4 z-50 bottom-20" v-if="page.sos">
      <svg class="h-12 w-12">
        <use xlink:href="#icon--sos"></use>
      </svg>
    </a>

    <TheInfobox />

  </div>
</template>

<style lang="scss">
  @import "./styles/app.scss";
</style>

<script>

import axios from "axios";
import Svg from '@/components/Svg.vue'
import TheNavigation from '@/components/TheNavigation.vue'
import TheServiceNavigation from '@/components/TheServiceNavigation.vue'
import TheFooter from '@/components/TheFooter.vue'
import Intro from '@/components/Intro.vue'
import Contents from '@/components/Contents.vue'
import TheCookieBanner from '@/components/TheCookieBanner.vue'
import TheInfobox from "@/components/TheInfobox";
/*import NotFound from "@/components/NotFound";*/

export default {
  name: 'App',
  data() {
    return {
      navigation: [],
      homepage: [],
      page: [],
      languages: [],

      introClosed: true,
      showNavbar: true,
      lastScrollPosition: 0,
      showFooter: true,

      pageNotFound: false,

      contents: null,
      contacts: null,
      season : '3'
    }
  },
  methods:{
    hideFooter() {
      this.showFooter = false
    },
    onScroll () {
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
      if (currentScrollPosition < 0) {
        return
      }
      // Stop executing this function if the difference between
      // current scroll position and last scroll position is less than some offset
      if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 60 && ((document.body.scrollHeight-window.outerHeight-currentScrollPosition) > 0)) {
        return
      }

      if(window.innerWidth <= 640){
        this.showNavbar = currentScrollPosition <= this.lastScrollPosition || ((document.body.scrollHeight-window.outerHeight-currentScrollPosition) < 0)
      }

      this.lastScrollPosition = currentScrollPosition

    }
  },
  created(){
    var that = this;
    axios.all([
      axios.get('https://api.corvatsch-diavolezza.ch' + window.location.pathname + '?type=834'),
      axios.get('https://api.corvatsch-diavolezza.ch' + window.location.pathname),
      axios.get('https://api.corvatsch-diavolezza.ch'),
    ]).then(
        axios.spread(
            function (navigation, page, homepage) {
              that.homepage = navigation.data['navigation'][0]['link'];
              that.navigation = navigation.data;
              that.season = ( typeof navigation.data['season'] !== 'undefined' && navigation.data['season']==='2')?'2':'3';
              that.page = page.data['page'];
              that.contents = page.data['content']['colPos0'];
              that.languages = page.data['languages'];

              /* Actual Language - set $i18n.locale */
              that.languages.forEach(function(language) {
                if(language['active'] == 1) {
                  that.$i18n.locale = language['twoLetterIsoCode'];
                }
              });

              /* Footer Contacts */
              var contacts_in_array = [];
              var contact_uid = 0;
              var contact_array = navigation.data['companyinfo'];
              var cs_contacts = homepage.data['page']['company'][0]['contacts'].split(",");

              contact_array.forEach(function(contact) {
                contact_uid = contact['uid'];
                if(cs_contacts.indexOf(contact_uid.toString()) !== -1){
                  contacts_in_array.push(contact);
                }
              });
              that.contacts = contacts_in_array;

              // SOCIAL MEDIA TAGS
              document.title = that.page.title || "guide.corvatsch-diavolezza.ch"

              var description = document.createElement('meta');
              description.setAttribute('name', 'description');
              description.content = page.data['page']['meta']['description'] != '' ? page.data['page']['meta']['description'] : homepage.data['page']['meta']['description'];
              document.getElementsByTagName('head')[0].appendChild(description);

              var og_link = document.createElement('meta');
              og_link.setAttribute('property', 'og:url');
              og_link.content = document.location;
              document.getElementsByTagName('head')[0].appendChild(og_link);

              var og_title = document.createElement('meta');
              og_title.setAttribute('property', 'og:title');
              og_title.content = page.data['page']['meta']['title'] != '' ? page.data['page']['meta']['title'] : homepage.data['page']['meta']['title'];
              document.getElementsByTagName('head')[0].appendChild(og_title);

              var og_description = document.createElement('meta');
              og_description.setAttribute('property', 'og:description');
              og_description.content = page.data['page']['socialMedia']['ogDescription'] != '' ? page.data['page']['socialMedia']['ogDescription'] : homepage.data['page']['socialMedia']['ogDescription'];
              document.getElementsByTagName('head')[0].appendChild(og_description);

              var og_type = document.createElement('meta');
              og_type.setAttribute('property', 'og:type');
              og_type.content = 'website';
              document.getElementsByTagName('head')[0].appendChild(og_type);

              var og_image = document.createElement('meta');
              og_image.setAttribute('property', 'og:image');
              og_image.content = page.data['page']['socialMedia']['ogImage'] != null ? page.data['page']['socialMedia']['ogImage'][0]['publicUrl'] : homepage.data['page']['socialMedia']['ogImage'][0]['publicUrl'];
              document.getElementsByTagName('head')[0].appendChild(og_image);

              var twitter_card = document.createElement('meta');
              twitter_card.setAttribute('property', 'twitter:card');
              twitter_card.content = page.data['page']['socialMedia']['twitterCard'] != '' ? page.data['page']['socialMedia']['twitterCard'] : homepage.data['page']['socialMedia']['twitterCard'];
              document.getElementsByTagName('head')[0].appendChild(twitter_card);

              var twitter_title = document.createElement('meta');
              twitter_title.setAttribute('property', 'twitter:title');
              twitter_title.content = page.data['page']['socialMedia']['twitterTitle'] != '' ? page.data['page']['socialMedia']['twitterTitle'] : homepage.data['page']['socialMedia']['twitterTitle'];
              document.getElementsByTagName('head')[0].appendChild(twitter_title);

              var twitter_description = document.createElement('meta');
              twitter_description.setAttribute('property', 'twitter:description');
              twitter_description.content = page.data['page']['socialMedia']['twitterDescription'] != '' ? page.data['page']['socialMedia']['twitterDescription'] : homepage.data['page']['socialMedia']['twitterDescription'];
              document.getElementsByTagName('head')[0].appendChild(twitter_description);

              var twitter_image = document.createElement('meta');
              twitter_image.setAttribute('property', 'twitter:image');
              twitter_image.content = page.data['page']['socialMedia']['twitterImage'] != null ? page.data['page']['socialMedia']['twitterImage'][0]['publicUrl'] : homepage.data['page']['socialMedia']['twitterImage'][0]['publicUrl'];
              document.getElementsByTagName('head')[0].appendChild(twitter_image);
            }
        )
    )
    .catch(() => {
      this.pageNotFound = true;
    })
    if(localStorage.getItem('introClosed')){
      this.introClosed = localStorage.getItem('introClosed')
    }else{
      this.introClosed = false
    }
    window.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.onScroll)
  },
  components: {
    /*NotFound,*/
    Svg,
    TheNavigation,
    TheServiceNavigation,
    TheFooter,
    Intro,
    Contents,
    TheCookieBanner,
    TheInfobox,
  }
}

</script>
