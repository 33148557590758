<template>
  <footer class="bg-d-white text-center">
    <div class="max-w-7xl mx-auto py-12 px-4 mb-24 md:mb-16 overflow-hidden sm:px-6 lg:px-8 space-y-5 md:space-y-8">

      <div class="pb-4" v-if="contacts">
        <h2 class="mb-4">{{ $t("labels.contact") }}</h2>
        <div class="space-y-6">
          <div v-for="(content, index) in contacts" :key="index">
            <p class="mb-3 text-gray-500">
              <strong>{{ content.name }}</strong><br/>
              {{ content.street }} {{ content.house_number }}<br/>
              {{ content.country_code }} - {{ content.zip_code }} {{ content.city }}<br/>
            </p>
            <div class="mb-3">
              <p v-if="content.parking_spot1">
                <a class="font-bold flex items-center justify-center" target="_blank" :href="content.parking_spot1.url">
                  <svg class="icon icon--pin w-4 h-4 mr-2"><use xlink:href="#icon--pin"></use></svg>
                  <span>{{ $t('labels.parking-spot') }} {{ content.parking_spot1.title }}</span>
                </a>
              </p>
              <p v-if="content.parking_spot2">
                <a class="font-bold flex items-center justify-center" target="_blank" :href="content.parking_spot2.url">
                  <svg class="icon icon--pin w-4 h-4 mr-2"><use xlink:href="#icon--pin"></use></svg>
                  <span>{{ $t('labels.parking-spot') }} {{ content.parking_spot2.title }}</span>
                </a>
              </p>
            </div>
            <p >
              <span v-if="index===0 || ( index>0 && contacts[index-1]['phone']!=content.phone )">
                <a :href="'tel:' + content.phone.replace(/\s/g, '').toLowerCase()" class="font-bold">{{ content.phone }}</a><br />
              </span>
              <span v-if="index===0 || ( index>0 && contacts[index-1]['email']!=content.email )">
                <a :href="'mailto:' + content.email.replace(/\s/g, '').toLowerCase()" class="font-bold">{{ content.email }}</a><br />
              </span>
            </p>
          </div>
        </div>

      </div>

      <nav class="-mx-5 my-2 pb-6 flex flex-wrap justify-center space-x-4" aria-label="Footer" v-if="navigation['socialMedia']">
        <div v-for="page in navigation['socialMedia']" :key="page.name">
          <a :href="page.url.url" :target="page.url.target" class="text-sm text-black hover:text-black transition-all duration-200 ease-in-out" v-if="page.nav_hide != 1">
            <span class="flex items-center">
              <svg class="ml-1 w-5 h-5"><use :xlink:href="'#icon&#45;&#45;' + page.name.replace(/\s+/g, '').toLowerCase()"></use></svg>
            </span>
          </a>
        </div>
      </nav>

      <nav class="-mx-5 my-2 flex flex-wrap justify-center" aria-label="Footer" v-if="navigation['footer']">
        <div v-for="(page, index) in navigation['footer']" :key="page.title">
          <a :href="page.link" :target="page.target" class="text-sm text-gray-500 hover:text-black transition-all duration-200 ease-in-out hover:underline" v-if="page.nav_hide != 1">
            <span class="flex items-center">
              {{ page.title }} <span class="px-2" v-if="index != Object.keys(navigation['footer']).length - 1"> | </span>
              <svg v-if="page.target == '_blank'" class="ml-1 w-4 h-4"><use xlink:href="#icon&#45;&#45;external-link"></use></svg>
            </span>
          </a>
        </div>
      </nav>

      <div>
        <p class="text-gray-500"><span v-html="$t('labels.footer-link')"></span></p>
      </div>

    </div>
  </footer>
</template>

<script>
export default {
  name: 'TheFooter',
  props: {
    navigation: Object,
    contacts: Array,
  },
}
</script>
